<div class="top-actions">
  <!-- Add investing entity -->
  <button type="button" mat-stroked-button color="primary" (click)="openCreateInvestingEntityDialog()" class="add-new covercy-wired-button">
    <mat-icon class="open_icon notranslate">add</mat-icon>
    Add Investing Entity
  </button>

</div>

<table mat-table [dataSource]="investingEntities$ | async" class="cov-basic-table table-narrow-sides gray-theme mat-elevation-z0">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="name-table-header"> Name</th>
    <td mat-cell *matCellDef="let element" class="lo_sensitive">
      <ng-container *ngIf="allowInvestorName$ | async">
        {{ element.name }}
      </ng-container>
      <ng-container *ngIf="!(allowInvestorName$ | async)">
        {{ element.nickname }}
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="investingEntityType">
    <th mat-header-cell *matHeaderCellDef> Type</th>
    <td mat-cell *matCellDef="let element"> {{ element.investingEntityType | enumString: InvestingEntityType }}</td>
  </ng-container>

  <ng-container matColumnDef="tin">
    <th mat-header-cell *matHeaderCellDef> TIN</th>
    <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{ element.tin }}</td>
  </ng-container>

  <ng-container matColumnDef="remarks">
    <th mat-header-cell *matHeaderCellDef class="remarks-table-header"> Remarks</th>
    <td mat-cell *matCellDef="let element" class="table-cell-truncated lo_sensitive"
        [matTooltip]="element?.remarks?.length > 20 ? element.remarks : ''" matTooltipClass="terra-tooltip"> {{ element.remarks }}
    </td>
  </ng-container>

	<ng-container matColumnDef="accountingExternalId">
		<th mat-header-cell *matHeaderCellDef> Accounting&nbsp;ID</th>
		<td mat-cell *matCellDef="let element" class="lo_sensitive"> {{element.accountingExternalId}} </td>
	</ng-container>

	<!-- Actions Column -->
	<ng-container matColumnDef="action">
		<th mat-header-cell *matHeaderCellDef class="actions-col-header"></th>
		<td mat-cell *matCellDef="let element" class="value-actions">
			<div class="flex flex-row align-items-center gap-1">
			<button type="button" mat-icon-button (click)="addBankAccount(element);$event.stopPropagation();"
							disableRipple matTooltip="Add Bank Account" matTooltipClass="terra-tooltip-compact">
				<mat-icon class="material-icons">add_business</mat-icon>
			</button>
			<button type="button" mat-icon-button class="edit-button"
        *ngIf="element.id" (click)="viewInvestingEntity(element.id);$event.stopPropagation();"
							disableRipple matTooltip="View" matTooltipClass="terra-tooltip-compact">
				<mat-icon class="edit-icon notranslate">remove_red_eye</mat-icon>
			</button>

			<button type="button" mat-icon-button class="edit-button" (click)="openEditInvestingEntityDialog(element);$event.stopPropagation();"
							disableRipple matTooltip="Edit" matTooltipClass="terra-tooltip-compact">
				<mat-icon class="edit-icon notranslate">edit</mat-icon>
			</button>
			<button *ngIf="!isAgentContact" type="button" mat-icon-button class="delete-button"
							(click)="delete(element); $event.stopPropagation(); (false)" disableRipple matTooltip="Delete"
							matTooltipClass="terra-tooltip-compact">
				<mat-icon class="delete-icon notranslate">delete</mat-icon>
			</button>
		</div>
		</td>
	</ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="row.id ? viewInvestingEntity(row.id) : openEditInvestingEntityDialog(row)" class="value-row"></tr>
</table>
