import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelpSourceTypeService } from 'src/app/services/help-source-type.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';

export class AccreditationSendRequestDialogParams{
  //output
  dontShowAgain:boolean;
  acceptTerms:boolean;

  //input
  hasPermission:boolean;
  reachingLimit:boolean;
  isProviderAccredited:boolean;
  accreditationPrice:string;

  constructor(hasPermission:boolean, reachingLimit:boolean, isProviderAccredited:boolean = false, accreditationPrice:string = '') {
    this.hasPermission = hasPermission;
    this.reachingLimit = reachingLimit;
    this.isProviderAccredited = isProviderAccredited;
    this.accreditationPrice = accreditationPrice;
  }
}

@Component({
  selector: 'terra-accreditation-send-request-dialog',
  templateUrl: './accreditation-send-request-dialog.component.html',
  styleUrls: ['./accreditation-send-request-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccreditationSendRequestDialogComponent implements OnInit {

  dontShowAgain = false;
  acceptTerms = false;

  paths = TerraUtils.consts.paths;

  constructor(
    private dialogRef: MatDialogRef<AccreditationSendRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AccreditationSendRequestDialogParams,
    private sourceTypeService: HelpSourceTypeService
  ) {
    this.dialogRef.updateSize('539px');
  }

  ngOnInit(): void {
  }

  proceed(){
    if(!!this.acceptTerms){
      this.dialogRef.close({proceed:true, dontShowAgain: this.dontShowAgain})
    }
  }

  cancel(){
    this.dialogRef.close()
  }

  talkToUS(){
    this.sourceTypeService.issueInput$.next(`I'd like to extend additional Automatic-Accreditation tokens`);
    this.dialogRef.close()
  }

}
