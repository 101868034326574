<h2 class="section-title">Tags (optional)</h2>
<div class="terra-form-field-wrapper">
  <label for="txtContactTags" class="text-input-label">
    <ng-content></ng-content>
    <br/>
    <strong>Tap the ENTER key after each tag you add</strong>
  </label>
  <mat-form-field appearance="outline">
    <mat-chip-grid #chipList class="terra-chip">
      <mat-chip *ngFor="let tag of selectedContactTags" [removable]="true" (removed)="removeTag.emit(tag)">
        {{ tag }}
        <mat-icon matChipRemove *ngIf="true" class="notranslate">cancel</mat-icon>
      </mat-chip>
      <input matInput id="txtContactTags" [formControl]="tagsFromControl" class="lo_sensitive"
             [matAutocomplete]="tagsAutocomplete"
             [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             (matChipInputTokenEnd)="addTagAsChip.emit($event)"/>
    </mat-chip-grid>
    <mat-autocomplete #tagsAutocomplete="matAutocomplete" (optionSelected)="selectedTagFromAutocomplete?.emit($event)">
      ֹֹ
      <mat-option *ngFor="let tag of filteredTagsForAutocomplete$ | async" [value]="tag">
        {{ tag }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="selectedContactTags.length >= 20">Maximum number of tags</mat-hint>
  </mat-form-field>
</div>

<ng-container *ngIf="filteredMostUsedTags as commonTags">
  <div class="new-row common-tags" *ngIf="commonTags && commonTags.length > 0">
    <label class="common-tags-title">Common tags:</label>
    <mat-chip-set class="terra-chip">
      <mat-chip *ngFor="let tag of commonTags" (click)="selectStaticChip.emit(tag.toLowerCase())">
        {{ tag.toLowerCase() }}
      </mat-chip>
    </mat-chip-set>
  </div>
</ng-container>
