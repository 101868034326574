import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { InvestorContactReqRes } from '../../../models/investorContactReqRes.model';
import { ContactReferrerReqRes } from 'src/app/shared/models/ContactReferrerReqRes.model';

export interface ContactDialogUiState {
  isUnsavedChanges: boolean;
  isSaving: boolean;
  isSubmitted: boolean;
  activeTabIndex: number;
}

export interface ContactDialogState {
  contact: InvestorContactReqRes;
  allContactReferrers: ContactReferrerReqRes[];
  ui: ContactDialogUiState;
}

export function createInitialState(): ContactDialogState {
  return {
    ui: {
      isUnsavedChanges: false,
      isSaving: false,
      isSubmitted: false,
      activeTabIndex: 0
    },
    contact: new InvestorContactReqRes(),
    allContactReferrers: []
  };
}

@StoreConfig({ name: 'contact-dialog' })
@Injectable()
export class ContactDialogStore extends Store<ContactDialogState> {
  constructor() {
    super(createInitialState());
  }
}
