import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, filter, map, of, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { InvestingEntityReqRes } from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import { DialogService } from 'src/app/services/dialog.service';
import { AccreditationDataService } from 'src/app/services/shared/accreditation-data.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import AccreditationStatus from 'src/app/shared/enums/AccreditationStatus.enum';
import { AccreditationDto, AccreditationProvider } from 'src/app/shared/models/AccreditationDto.model';
import { BaseResponseDto, BaseResponseStatus } from 'src/app/shared/models/BaseResponseDto.model';
import { StorageService } from 'src/app/services/storage.service';
import { AccreditationSendRequestDialogComponent, AccreditationSendRequestDialogParams } from './accreditation-send-request-dialog/accreditation-send-request-dialog.component';
import { LoggerService } from 'src/app/shared/errors/logger.service';
import { AccreditationService } from './accreditation.service';

import { TerraUtils } from 'src/app/shared/TerraUtils';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { SystemFeatureFlagConsts } from 'src/app/shared/consts/SystemFeatureFlags.consts';
import PermissionLevel from 'src/app/permission/enums/permissionLevel.enum';
import { NoPermissionAction } from 'src/app/shared/directives/check-permissions.directive';
import { GeneralSettingsService } from 'src/app/services/shared/general-settings.service';


@Component({
  selector: 'terra-accreditation',
  templateUrl: './accreditation.component.html',
  styleUrls: ['./accreditation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccreditationComponent implements OnInit, OnDestroy {
  @Input() accreditationDateControl: UntypedFormControl;
  @Input() investingEntity: InvestingEntityReqRes;
  @Input() onlyDatePicker: boolean;
  @Input() autoSaveDate: boolean;
  @Input() holdingId: number = null;

  readonly today = new Date();
  readonly minDate = new Date('01/01/1753');
  isAccredited$: Observable<boolean>;
  // disableAccreditationBtn$: Observable<boolean>;
  isLoadingAccreditationSend;
  forceManualAccreditation = false;

  downloadCertificateLoading$ = new BehaviorSubject<boolean>(false);

  FeatureFlagConsts = SystemFeatureFlagConsts;
  PermissionLevel = PermissionLevel;
  NoPermissionAction = NoPermissionAction;


  @ViewChild('accreditationDate') accreditationDate: MatDatepicker<any>;


  destroy$ = new Subject<void>();

  AccreditationStatus = AccreditationStatus;
  AccreditationProvider = AccreditationProvider;

  get accreditation(): AccreditationDto {
    return this.investingEntity?.accreditation;
  }



  constructor(
    private accreditationDataService: AccreditationDataService,
    private accreditationService: AccreditationService,
    private dialogService: DialogService,
    private snackBarService: SnackbarService,
    private utilService: UtilsService,
    private storageService: StorageService,
    private matDialog: MatDialog,
    private loggerService: LoggerService,
    private appSettingService: AppSettingsService,
    private snackbarService: SnackbarService,
    private generalSettingsService: GeneralSettingsService,
    private cdr: ChangeDetectorRef
  ) {
    this.minDate.setDate(this.minDate.getDate() - 89);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.accreditationDateControl.disable({emitEvent:false});

    this.isAccredited$ = this.accreditationService.isAccreditedInvestor$(this.accreditationDateControl.valueChanges.pipe(
      startWith(this.accreditation?.accreditedDate))).pipe(
        map(isAccredited => {
            return isAccredited || this.accreditation?.status === AccreditationStatus.Accredited
        })
      );

    if (this.autoSaveDate) {
      this.autoSaveDateChange();
    }

  }

  get disableAccreditationBtn() {
    if (!!this.accreditation) {
      return this.accreditation.status === AccreditationStatus.Pending
        || this.accreditation.status === AccreditationStatus.Requested;
    }
    return false;
  }

  get linkRequest():string{
    return `${this.appSettingService.lpFrontEnvironmentAppBaseUrl}lp/overview?email=${encodeURIComponent(this.investingEntity.contact.email)}&accReq=${this.accreditation.id}&isRegistered=${!!this.investingEntity.contact.lpClientDetailsId}`;
  }

  snackbarMessage(itemName: string, e: Event) {
    if (!!itemName) {
      this.snackbarService.showGeneralMessage(`${itemName} was copied to the clipboard`, 1);
    }
    e.preventDefault();
  }

  private autoSaveDateChange() {
    this.accreditationDateControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(300),
      distinctUntilChanged(),
      tap(_ => console.log(this.accreditationDateControl.invalid, this.accreditationDateControl)),
      filter(accreditationDateControl => !!accreditationDateControl
        && this.autoSaveDate
        && (!this.accreditation || this.accreditation.provider === AccreditationProvider.Manual || this.forceManualAccreditation)),
      switchMap((date: Date) => {
        const model = new AccreditationDto();
        model.id = this.accreditation?.id;
        model.investingEntityId = this.investingEntity?.id;
        model.provider = AccreditationProvider.Manual;
        model.accreditedDate = TerraUtils.forceUtc(date);//new Date(date.toUTCString())
        model.status = AccreditationStatus.Accredited;
        model.accreditationRequire = false;
        model.holdingId = this.holdingId;
        return this.accreditationDataService.sendManualAccreditation(model)
      })
    )
      .subscribe({
        next: res => {
          if (!!res && res.responseStatus === BaseResponseStatus.Success) {
            this.investingEntity.accreditation = res;
            this.snackBarService.showGeneralMessage("Accreditation Succeeded");
            this.cdr.markForCheck();
          }
          else if (!!res && res.responseStatus === BaseResponseStatus.Error) {
            this.utilService.alertErrorMessage(res);
          }
          else {
            this.snackBarService.showGeneralMessage("Accreditation Failed")
          }
        },
        error: err => {
          this.loggerService.error(`${this.constructor.name} => Cannot update manual accreditation of investingEntityId: ${this.investingEntity.id}`, err)
          this.snackBarService.showGeneralMessage("Accreditation Failed")
        }
      });
  }

  get isProviderAccreditedOrProgress(){
    return !!this.accreditation &&
    this.accreditation.provider !== AccreditationProvider.None &&
    this.accreditation.provider !== AccreditationProvider.Manual && (
      this.accreditation.status === AccreditationStatus.Accredited ||
      this.accreditation.status === AccreditationStatus.Requested ||
      this.accreditation.status === AccreditationStatus.Pending
    );
  }


  openConfirmDialogWhenLpAccredited() {
    if (this.isProviderAccreditedOrProgress) {
      const config = new ConfirmDialogParams();
      config.title = 'Set Accreditation Date';
      config.actionLabel = 'Proceed';
      config.actionPosition = 'right';
      config.panelClass = 'fundraising-accreditation-lp-accredited';

      if (this.accreditation.status === AccreditationStatus.Accredited) {
        config.description = `<div><strong>Important Notice:</strong>
        The accreditation date was set by an accreditation agency, overriding it is irreversible.</div>
        <div>Are you sure you want to proceed?</div>`;
      }
      else if (this.accreditation.status === AccreditationStatus.Requested || this.accreditation.status === AccreditationStatus.Pending) {
        config.title = 'Set Accreditation Date';
        config.description = `<div><strong>Important Notice:</strong>
        An accreditation process is already underway. Are you certain about canceling it and manually setting a date?
        Please be aware that the costs for the initiated process cannot be reimbursed.</div>
        <div>Are you sure you want to proceed?</div>`;
      }

      this.generalSettingsService.systemFeatureFlagKeysValues$.pipe(
        take(1),
        switchMap(systemFeatureFlagKeysValues => {
          if(systemFeatureFlagKeysValues[SystemFeatureFlagConsts.SystemFeatureFlagAccreditationOn]){
            return this.dialogService.confirmDialog(config).afterClosed().pipe(take(1));
          }
          else{
            return of(true);
          }
        })
      ).subscribe(
          c => {
            if (!!c) {
              this.forceManualAccreditation = true;
              this.accreditationDate.open();
            }
            else {
              this.forceManualAccreditation = false;
              this.accreditationDate.close();
            }
          }
        )
    }
  }


  sendAccreditationRequest() {
    const model = new AccreditationDto();
    model.investingEntityId = this.investingEntity?.id;
    model.accreditationRequire = true;
    model.status = AccreditationStatus.Requested;
    model.holdingId = this.holdingId;
    model.provider = AccreditationProvider.VerifyInvestor;

    const config = new MatDialogConfig();
    config.disableClose = true;

    combineLatest([this.accreditationService.enableToSendAccreditationRequest$, this.accreditationService.reachingLimitToSendAccreditationRequest$, this.accreditationService.accreditationPrice$]).pipe(
      take(1),
      switchMap(([enable, reaching, accreditationPrice]) => {
        const resendAccreditationRequest = this.accreditation?.provider !== AccreditationProvider.Manual && this.accreditation?.status === AccreditationStatus.Accredited;
        const notShowConfirmation = this.storageService.getAccreditationDontShowConfirmation();
        if(enable && !reaching && notShowConfirmation && !resendAccreditationRequest){
          return of({proceed: true, dontShowAgain:true});
        }
        const data = new AccreditationSendRequestDialogParams(
          enable,
          reaching,
          resendAccreditationRequest,
          accreditationPrice
        );
        config.data = data;

        return this.matDialog.open(AccreditationSendRequestDialogComponent, config)
        .afterClosed();
      }),
      filter(confirm => !!confirm && confirm.proceed),
      switchMap(confirm => {
        this.storageService.setAccreditationDontShowConfirmation(confirm.dontShowAgain)
        return this.accreditationService.sendAccreditationRequestToLp(model)
      })
    ).subscribe({
        next: res => {
          if (!!res && res.responseStatus === BaseResponseStatus.Success) {
            this.investingEntity.accreditation = res;
            this.forceManualAccreditation = false
            this.accreditationDateControl.setValue(null, {emitEvent:false});
            this.snackBarService.showGeneralMessage(`Accreditation request sent to ${this.investingEntity.name}`)
            this.cdr.markForCheck();
          }
          else if (!!res && res.responseStatus === BaseResponseStatus.Error || res.responseStatus === BaseResponseStatus.Notice) {
            this.utilService.alertErrorMessage(res);
          }
          else {
            this.snackBarService.showGeneralMessage("Accreditation request failed")
          }
        },
        error: err => {
          // this.loggerService.error(`${this.constructor.name} => Cannot send accreditation request to investingEntityId: ${this.investingEntity.id}`, err)
          this.snackBarService.showGeneralMessage("Accreditation request failed")
        }
      });
  }

  downloadAccreditationCertificate() {
    if (!this.accreditation
      || this.accreditation.provider === AccreditationProvider.Manual
      || this.accreditation.status !== AccreditationStatus.Accredited) {
      return;
    }

    this.downloadCertificateLoading$.next(true);

    this.accreditationDataService.downloadAccreditationCertificate(this.investingEntity?.id, this.accreditation.id)
      .pipe(
        withLatestFrom(this.isAccredited$),
        filter(([ac, isAccredited]) => !!isAccredited),
        map(([acc, _]) => acc)
      )
      .subscribe({
        next: acc => {
          if (!!acc && acc.responseStatus === BaseResponseStatus.Error) {
            this.utilService.alertErrorMessage(acc);
          }
          this.downloadCertificateLoading$.next(false);
        },
        error: err => {
          this.loggerService.error(`${this.constructor.name} => Cannot download accreditation certificate of investingEntityId: ${this.investingEntity.id}`, err)
          const er = new BaseResponseDto();
          er.responseStatus = BaseResponseStatus.Error;
          this.utilService.alertErrorMessage(er);
          this.downloadCertificateLoading$.next(false);
        }
      })
  }


}
