<div class="send-request-dialog-container">
    <terra-dialog-header [showCloseButton]="true">
        <h1>Send Accreditation Request</h1>
    </terra-dialog-header>
    <div class="content-send-request-dialog">
        <ng-container *ngIf="!!data.hasPermission && !data.reachingLimit">
            <ng-container *ngIf="!data.isProviderAccredited">
                <div class="sub-title">
                    Automate accreditation process.
                </div>
                <div class="description">
                    Send automatic requests to investors via email and Investor Portal. Accreditation status and date
                    updated
                    instantly upon completion.
                </div>
                <div class="hint">
                    *Required for US-based entities only.
                </div>
            </ng-container>

            <ng-container *ngIf="data.isProviderAccredited">
                <div class="description">
                    This investor is already accredited. The accreditation was set by an accreditation agency, overriding it is irreversible.
                </div>
            </ng-container>

            <div class="term-container">
                <div class="sub-title">For your information</div>
                <div *ngIf="!data.isProviderAccredited">Each accreditation request costs ${{data.accreditationPrice}} (Regardless of completion)</div>
                <div *ngIf="data.isProviderAccredited">Accreditation request costs ${{data.accreditationPrice}} (Regardless of completion)</div>
                <div>
                    The accreditation is validated by
                    <a href="https://www.verifyinvestor.com/" target="_blank">Verify Investor.</a>
                </div>
                <mat-checkbox color="primary" [(ngModel)]="acceptTerms">
                    <a href="https://storage.googleapis.com/public-disclosures.tzero.com/tZERO_MasterTermsofUse.pdf" target="_blank">I accept Verify Investor terms of service</a>
                </mat-checkbox>
            </div>
            <br>
            <mat-checkbox *ngIf="!data.isProviderAccredited" color="primary" [(ngModel)]="dontShowAgain">
                Don’t show me this message again
            </mat-checkbox>

            <ng-container *ngIf="data.isProviderAccredited">
                <div class="description">
                    Are you sure you want send request anyway?
                </div>
            </ng-container>

        </ng-container>

        <ng-container *ngIf="!!data.hasPermission && !!data.reachingLimit">
            <div>
                You've reached the accreditation request limit based on your plan. Please contact our Customer Support
                to
                unlock additional requests.
            </div>
        </ng-container>

        <ng-container *ngIf="!data.hasPermission">
            <div class="sub-title">
                Automate accreditation process.
            </div>
            <div>Send automatic requests to investors via email and Investor Portal.</div>
            <br>
            <div>This service is not included in your plan. Please contact our Customer Support for more information.
            </div>
        </ng-container>
    </div>
    <div mat-dialog-actions [ngClass]="{'actions':true, 'revers-action': data.isProviderAccredited && !!data.hasPermission && !data.reachingLimit}">
        <button [mat-dialog-close] type="button" mat-button aria-label="Close" [ngClass]="{'primary-button': data.isProviderAccredited && !!data.hasPermission && !data.reachingLimit}">
            Cancel
        </button>
        <ng-container *ngIf="!!data.hasPermission && !data.reachingLimit; else talkToUs">
            <button mat-flat-button [disabled]="!acceptTerms" cdkFocusInitial [ngClass]="{'primary-button': !data.isProviderAccredited}" (click)="proceed()">
                Send Request
            </button>
        </ng-container>
        <ng-template #talkToUs>
            <a class="primary-button mat-flat-button" (click)="talkToUS()" [routerLink]="paths.SUPPORT">Talk to Us</a>
        </ng-template>
    </div>
</div>
