import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {ContactReferrerReqRes} from 'src/app/shared/models/ContactReferrerReqRes.model';
import {Contact} from './contact.model';
import { AccreditationDto } from 'src/app/shared/models/AccreditationDto.model';

export class InvestorContactReqRes extends Contact {
  public middleName = '';
  public phone: string;
  public email: string;
  public additionalEmails: string[];
  public personalRemarks: string;
  public contactInvestingEntities: InvestingEntityReqRes[] = new Array<InvestingEntityReqRes>();
  public contactReferrerId: number;
  public contactReferrer: ContactReferrerReqRes;
  public isAgentContact = false;
  public isConnectedToActiveAssets: boolean;
  public lpClientDetailsId: number;
  public isFamilyOffice = false;
  public investingEntityAccountingExternalIdList: string;
  public uniqueIdentifier: string;
  // used when creating a new contact
  public accreditationDate: Date;
  public accreditation:AccreditationDto;
}
