export enum BeneficiaryEntityType {
  None = 0,
  LLC,
  Company,
  Partnership,
  Corporation
}

export namespace BeneficiaryEntityType {
  export function toString(beneficiaryEntityType: BeneficiaryEntityType): string {
    switch (beneficiaryEntityType) {
      case BeneficiaryEntityType.LLC:
        return 'LLC';
      case BeneficiaryEntityType.Company:
        return 'Company';
      case BeneficiaryEntityType.Partnership:
        return 'Partnership';
      case BeneficiaryEntityType.Corporation:
        return 'Corporation';
      default:
        return null;
    }
  }

  export function listAll() {
    return [BeneficiaryEntityType.LLC, BeneficiaryEntityType.Company, BeneficiaryEntityType.Partnership, BeneficiaryEntityType.Corporation];
  }

  export function parse(beneficiaryEntityTypeStr: string): BeneficiaryEntityType {
    return BeneficiaryEntityType[beneficiaryEntityTypeStr];
  }
}

export default BeneficiaryEntityType;
