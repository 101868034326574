import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { TerraUtils } from 'src/app/shared/TerraUtils';
import { ContactReferrerReqRes } from 'src/app/shared/models/ContactReferrerReqRes.model';

export class ContactReferrerEditDialogContext {
  includeFindFeatureDirection = false;
  contactReferrer: ContactReferrerReqRes = null;
}

@Component({
  selector: 'terra-investor-contact-referrer-dialog',
  templateUrl: './investor-contact-referrer-dialog.component.html',
  styleUrls: ['./investor-contact-referrer-dialog.component.scss']
})
export class InvestorContactReferrerDialogComponent implements OnInit {

  pageForm: UntypedFormGroup;
  isSubmitted$ = new BehaviorSubject(false);

  constructor(
    private dialogRef: MatDialogRef<InvestorContactReferrerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: ContactReferrerEditDialogContext,
    private fb: UntypedFormBuilder
  ) {
    dialogRef.addPanelClass('terra-investor-contact-referrer-dialog');
    this.generateForm();
  }

  ngOnInit() {
  }

  generateForm() {
    this.pageForm = this.fb.group({
      name: [this.context && this.context.contactReferrer ? this.context.contactReferrer.name : null,
      [Validators.required, Validators.maxLength(TerraUtils.consts.validators.GENERAL_TEXT_LENGTH)]]
    });
  }

  generateSubmitModel() {
    const model = new ContactReferrerReqRes();
    model.name = this.pageForm.value.name;
    if (this.context && this.context.contactReferrer && this.context.contactReferrer.id) {
      model.id = this.context.contactReferrer.id;
    }
    return model;
  }

  submit() {
    this.isSubmitted$.next(true);
    if (!this.pageForm.valid) {
      return;
    }
    const model = this.generateSubmitModel();
    this.dialogRef.close(model);
  }
}
