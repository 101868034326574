import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, startWith } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import { InvestingEntityReqRes } from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import { LocationDetailsResponse } from 'src/app/shared/models/LocationDetailsResponse.model';
import { AddressFields, AddressFormSettings } from 'src/app/shared/components/address-form/address-form.settings';
import { TerraUtils } from '../../../../../shared/TerraUtils';
import { CustomValidators } from '../../../../../shared/validators/custom.validators';
import AccreditationStatus from 'src/app/shared/enums/AccreditationStatus.enum';
import { AccreditationDto, AccreditationProvider } from 'src/app/shared/models/AccreditationDto.model';

export interface EditContactInvestingEntityContext {
  id: number;
  investingEntity: InvestingEntityReqRes;
  contactLocationDetails: LocationDetailsResponse;
  contactFullName: string;
}

@Component({
  selector: 'terra-edit-investing-entity-dialog',
  templateUrl: './edit-investing-entity-dialog.component.html',
  styleUrls: ['./edit-investing-entity-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditInvestingEntityDialogComponent extends OnDestroyMixin implements OnInit {

  // consts
  readonly InvestingEntityType = InvestingEntityType;
  readonly forbiddenNameCharacters = TerraUtils.consts.validators.FORBIDDEN_CHARACTERS_IN_NAME;
  readonly today = new Date();

  initialLocationDetails: LocationDetailsResponse;

  pageForm: UntypedFormGroup;

  get addressForm() {
    return this.pageForm.get('investingEntityLocation') as UntypedFormGroup;
  }

  isSubmitted$ = new BehaviorSubject(false);
  addressFormSettings = new AddressFormSettings(undefined, AddressFields.None);

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<EditInvestingEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditContactInvestingEntityContext,
    private fb: UntypedFormBuilder
  ) {
    super();
    dialogRef.addPanelClass('terra-edit-investing-entity-dialog');

    if (data.investingEntity && !data.investingEntity.useContactLocation) {
      this.initialLocationDetails = data.investingEntity.investingEntityLocation;
    } else {
      this.initialLocationDetails = data.contactLocationDetails;
    }
  }

  ngOnInit() {
    this.generateForm();
    this.handleToggleUseContactLocation();
    this.populateForm();
    this.changeDetectorRef.markForCheck();
  }

  submit() {
    this.isSubmitted$.next(true);
    this.pageForm.markAllAsTouched();

    if (!this.pageForm.valid) {
      return;
    }

    const model = this.generateSubmitModel();
    this.dialogRef.close(model);
  }

  private generateForm() {
    this.pageForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), CustomValidators.containsForbiddenCharacterValidator(this.forbiddenNameCharacters)]],
      investingEntityType: [null, Validators.required],
      tin: '',
      accountingExternalId: '',
      accreditationDate: null,
      remarks: '',
      useContactLocation: true,
      investingEntityLocation: { value: {}, disabled: true }
    });

    this.pageForm.get('investingEntityType').valueChanges.subscribe((investingEntityType: InvestingEntityType) => {
      if (investingEntityType === InvestingEntityType.Personal) {
        const nameField = this.pageForm.get('name');
        if (!nameField.value && this.data.contactFullName) {
          nameField.setValue(this.data.contactFullName);
        }
      }
    });
  }

  private populateForm() {
    const entity = this.data.investingEntity;
    if (entity) {
      this.pageForm.patchValue({
        name: entity.name,
        investingEntityType: entity.investingEntityType,
        tin: entity.tin,
        accountingExternalId: entity.accountingExternalId,
        accreditationDate: TerraUtils.forceUtcToLocal(entity.accreditation?.accreditedDate),
        remarks: entity.remarks,
        useContactLocation: entity.useContactLocation,
      });
    }
  }

  private generateSubmitModel() {
    const model = new InvestingEntityReqRes();
    model.id = this.data.id;

    const formValues = this.pageForm.getRawValue();
    model.name = formValues.name;
    model.investingEntityType = formValues.investingEntityType;
    model.tin = formValues.tin;
    model.accountingExternalId = formValues.accountingExternalId;
    // model.accreditationDate = TerraUtils.forceUtc(formValues.accreditationDate);
    model.remarks = formValues.remarks;
    model.useContactLocation = formValues.useContactLocation;
    
    if (!!formValues.accreditationDate && !!model.accreditation) {
      model.accreditation.provider = AccreditationProvider.Manual;
      model.accreditation.accreditedDate = TerraUtils.forceUtc(formValues.accreditationDate);
      model.accreditation.status = AccreditationStatus.Accredited;
      model.accreditation.accreditationRequire = false;
    }

    if (!!formValues.accreditationDate && !model.accreditation) {
      model.accreditation = new AccreditationDto();
      model.accreditation.provider = AccreditationProvider.Manual;
      model.accreditation.accreditedDate = TerraUtils.forceUtc(formValues.accreditationDate);
      model.accreditation.status = AccreditationStatus.Accredited;
      model.accreditation.accreditationRequire = false;
    }

    if (!model.useContactLocation) {
      const loactionValue = formValues.investingEntityLocation;
      model.investingEntityLocation = new LocationDetailsResponse();
      model.investingEntityLocation.countryId = loactionValue.countryId;
      model.investingEntityLocation.stateName = loactionValue.stateName;
      model.investingEntityLocation.cityName = loactionValue.cityName;
      model.investingEntityLocation.streetName = loactionValue.streetName;
      model.investingEntityLocation.houseNumber = loactionValue.houseNumber;
      model.investingEntityLocation.postalCode = loactionValue.postalCode;
    }
    return model;
  }

  private handleToggleUseContactLocation() {
    this.pageForm.get('useContactLocation').valueChanges.pipe(
      untilComponentDestroyed(this),
      distinctUntilChanged())
      .subscribe(useContactLocation => {
        if (useContactLocation) {
          this.addressForm.disable();
        } else {
          this.addressForm.enable();
        }
        this.addressForm.updateValueAndValidity();
      });
  }
}
