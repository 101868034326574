<ng-container *ngIf="{contactDetails: contactDetails$ | async}  as vm">
  <div class="info-message-compact contact-connected-message"
       *ngIf="vm.contactDetails && vm.contactDetails.isConnectedToActiveAssets">
    This contact is connected to an active asset. Any changes made to this contact's information will be applied
    immediately.
  </div>

  <div [formGroup]="pageForm" class="cov-form-grid">
    <section class="form-section">
      <h2 class="section-title first">Personal info</h2>

      <div class="full-width row-fields-wrapper">
        <!-- firstName -->
        <div class="terra-form-field-wrapper">
          <label for="txtFirstName" class="text-input-label">First name</label>
          <mat-form-field appearance="outline">
            <input id="txtFirstName" matInput formControlName="firstName" class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('firstName').hasError('required')"> Required</mat-error>
            <mat-error *ngIf="pageForm.get('firstName').hasError('maxlength')"> Enter a name up to 40 characters
            </mat-error>
            <mat-error *ngIf="pageForm.get('firstName').hasError('illegalCharacter')">
              Name shouldn't contain the following characters: {{forbiddenNameCharacters.join(', ')}}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- middleName -->
        <div class="terra-form-field-wrapper">
          <label for="txtMiddleName" class="text-input-label">Middle name (optional)</label>
          <mat-form-field appearance="outline">
            <input id="txtMiddleName" matInput formControlName="middleName" class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('middleName').hasError('maxlength')"> Enter a name up to 40 characters
            </mat-error>
            <mat-error *ngIf="pageForm.get('middleName').hasError('illegalCharacter')">
              Name shouldn't contain the following characters: {{forbiddenNameCharacters.join(', ')}}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- lastName -->
        <div class="terra-form-field-wrapper">
          <label for="txtLastName" class="text-input-label">Last name</label>
          <mat-form-field appearance="outline">
            <input id="txtLastName" matInput formControlName="lastName" class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('lastName').hasError('required')"> Required</mat-error>
            <mat-error *ngIf="pageForm.get('lastName').hasError('maxlength')"> Enter a name up to 40 characters
            </mat-error>
            <mat-error *ngIf="pageForm.get('lastName').hasError('illegalCharacter')">
              Name shouldn't contain the following characters: {{forbiddenNameCharacters.join(', ')}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="full-width row-fields-wrapper">
        <!-- Email -->
        <div class="terra-form-field-wrapper email-wrapper">
          <label for="txtEmail" class="text-input-label">Email</label>
          <mat-form-field appearance="outline">
            <input id="txtEmail" matInput formControlName="email" class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('email').hasError('required')"> Required</mat-error>
            <mat-error *ngIf="pageForm.get('email').hasError('email')"> Please enter a valid email address</mat-error>
          </mat-form-field>
        </div>

        <!-- Phone number -->
        <div class="terra-form-field-wrapper">
          <label for="txtPhone" class="text-input-label">Phone (optional)</label>
          <mat-form-field appearance="outline">
            <input id="txtPhone" matInput formControlName="phone" type="tel" class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('phone').hasError('required')">Required</mat-error>
            <mat-error *ngIf="pageForm.get('phone').hasError('maxlength')">Please enter up to 20 characters</mat-error>
          </mat-form-field>
        </div>

        <div class="terra-form-field-wrapper family-office-wrapper">
          <mat-checkbox color="primary" formControlName="isFamilyOffice">
            <h5>Family Office</h5>
          </mat-checkbox>
        </div>
      </div>

      <div class="full-width row-fields-wrapper">
        <!-- Accreditation Date -->
        <ng-container *ngIf="!(isLoadingDataContact$ | async) && !vm.contactDetails.id" >

          <terra-accreditation 
          [onlyDatePicker]="true"
          [autoSaveDate]="false"
          [accreditationDateControl]="pageForm.get('accreditationDate')"></terra-accreditation>
        </ng-container>


        <div class="terra-form-field-wrapper additional-emails" role="button">
          <label class="text-input-label">
            Additional emails (optional)
            <mat-icon class="notranslate" color="primary" matTooltipClass="terra-tooltip tin-tooltip"
                      matTooltipPosition="right"
                      matTooltip="Additional email addresses will receive some of the updates and will not have access to the investor portal.">
              help
            </mat-icon>
          </label>
          <div (click)="additionalEmailsEdit()" class="emails-list-wrapper"
               *ngIf="(additionalEmails$ | async)?.length >= 0 as additionalEmails" matRipple>
            <ul class="emails-list" *ngIf="additionalEmails && additionalEmails.length > 0 else addMoreIcon">
              <li *ngFor="let email of additionalEmails">{{email}}</li>
            </ul>
            <ng-template #addMoreIcon>
              <!-- <mat-icon class="add-email-icon notranslate" *ngIf="">add</mat-icon>-->
              <mat-icon class="add-email-icon notranslate">add</mat-icon>
            </ng-template>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact referrer -->
    <section class="form-section contact-referrer">
      <h2 class="section-title">Contact Referrer (optional)</h2>
      <div class="terra-form-field-wrapper new-row">
        <label for="ddlContactReferrerId" class="text-input-label">Select a contact referrer</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="contactReferrerId" id="ddlContactReferrerId" placeholder="Select">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let contactReferrer of allContactReferrers$ | async" [value]="contactReferrer.id">
              {{ contactReferrer.name | truncate : 40 : true }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <a role="button" (click)="addContactReferrerDialog()" class="link btn-add-new-referrer">Add a new referrer</a>
      </div>
    </section>

    <!-- Contact Tags -->
    <section class="form-section tags-section">

      <terra-contact-tags (addTagAsChip)="addTagAsChip($event)" (removeTag)="removeTag($event)"
                          (selectedTagFromAutocomplete)="selectedTagFromAutocomplete($event)"
                          (selectStaticChip)="selectStaticChip($event)" [filteredMostUsedTags]="filteredMostUsedTags$ | async"
                          [selectedContactTags]="selectedContactTags$ | async" [tagsFromControl]="pageForm.get('contactTagsTerm')">
        Using tags to describe clients makes matching them to assets easier.
      </terra-contact-tags>
    </section>

    <!-- Remarks -->
    <section class="form-section remarks-section">
      <h2 class="section-title">Remarks (optional)</h2>

      <div class="terra-form-field-wrapper full-width">
        <label for="txtPersonalRemarks" class="text-input-label">(Only visible to you)</label>
        <mat-form-field appearance="outline">
          <textarea id="txtPersonalRemarks" (keydown.enter)="$event.stopPropagation()" matInput
                    formControlName="personalRemarks"
                    class="lo_sensitive"></textarea>
        </mat-form-field>
      </div>
    </section>

    <!-- Address -->
    <section class="form-section single-column">
      <h2 class="section-title">Address</h2>
      <terra-google-map-search-input class="inner-form-section single-column" formControlName="contactLocation" [isOuterFormSubmitted]="isSubmitted$ | async" *ngIf="vm.contactDetails !== undefined && (displayAddress$ | async)" ></terra-google-map-search-input>
    </section>
  </div>
</ng-container>
