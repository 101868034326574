import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'terra-contact-tags',
  templateUrl: './contact-tags.component.html',
  styleUrls: ['./contact-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactTagsComponent implements OnInit {
  @Input() selectedContactTags: string[];
  @Input() filteredMostUsedTags: string[];
  @Input() tagsFromControl: UntypedFormControl;
  @Input() toAutoCompleteTags = true;

  @Output() removeTag = new EventEmitter<string>();
  @Output() addTagAsChip = new EventEmitter<MatChipInputEvent>();
  @Output() selectedTagFromAutocomplete = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() selectStaticChip = new EventEmitter<string>();

  readonly separatorKeysCodes = [ENTER, COMMA];
  filteredTagsForAutocomplete$: Observable<any[]>;

  constructor() { }

  ngOnInit(): void {
  }

}
