import {LocationDetailsResponse} from '../../../shared/models/LocationDetailsResponse.model';
import CheckingOrSavingsType from '../../../shared/enums/CheckingOrSavingsType.enum';

export abstract class Contact {
  public id: number;
  public firstName = '';
  public lastName = '';
  public nickname = '';
  public contactTags: string[] = new Array<string>();
  public contactLocation: LocationDetailsResponse;
  public memo: string;
  public checkingOrSavingsType: CheckingOrSavingsType;

  // for ui only
  public checked = false;
  public hover: boolean;
}
