<terra-loader class="page-loader" *ngIf="displayLoader$ | async" />

<terra-dialog-header [bottomMargin]="false">
  <h1 *ngIf="{contactDetails: contactDetails$ | async} as headerModel" class="lo_sensitive">
    <ng-container *ngIf="isEditMode && headerModel.contactDetails">
      {{ getContactFullName(headerModel.contactDetails) }}
    </ng-container>
    <ng-container *ngIf="!isEditMode">
      New Contact
    </ng-container>
  </h1>
</terra-dialog-header>

<!-- (selectedTabChange)="tabChanged($event)" -->
<form id="dialogForm" mat-dialog-content #formRef="ngForm" [formGroup]="dialogForm" [ngClass]="{'loading': displayLoader$ | async}">

  <!-- tabIndex$ | async -->
  <mat-tab-group  animationDuration="0ms" [selectedIndex]="tabIndex$ | async" mat-stretch-tabs="false" mat-align-tabs="start">
    <!-- Contact details tab -->
    <mat-tab>
      <!-- Label -->
      <ng-template mat-tab-label>
        <span class="tab-label-text" [class.invalid]="formRef.submitted && !dialogForm?.get('contactDetails')?.valid" #tab1Label>
          Contact details
        </span>
        <mat-chip-grid selectable="false" *ngIf="formRef.submitted && !dialogForm?.get('contactDetails')?.valid">
          <mat-chip class="missing-info-in-tab-warning" disabled>Missing</mat-chip>
        </mat-chip-grid>
      </ng-template>
      <!-- Content -->
      <terra-investor-contact-details-tab />
    </mat-tab>

    <!-- Investing entities tab -->
    <mat-tab label="Investing entities">
      <ng-template mat-tab-label>
        <span class="tab-label-text">
          Investing entities
        </span>
      </ng-template>
      <terra-investor-contact-investing-entities-tab [isAgentContact]="(contactDetails$ | async).isAgentContact" />
    </mat-tab>
  </mat-tab-group>
</form>

<mat-dialog-actions>
  <button type="submit" form="dialogForm" (click)="save()" [disabled]="isSaveButtonDisabled$ | async" mat-flat-button color="primary"
    class="save-button">Save</button>

  <button type="button" mat-button class="cancel-button" [mat-dialog-close]="undefined" [disabled]="(isSaving$ | async)">
    Cancel
  </button>

  <!-- Error message -->
  <div class="full-width" *ngIf="isSubmitted$ | async">
    <p *ngIf="dialogForm.invalid" class="error-message">
      Please review and correct the fields marked in red
    </p>
    <p *ngIf="dialogForm.valid && (isGeneralServerError$ | async)" #serverError class="error-message">
      <span [innerHTML]="generalServerErrorMessage$ | async"></span></p>
  </div>
</mat-dialog-actions>
