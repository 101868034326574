<terra-dialog-header [bottomMargin]="false">
  <h1>
    Additional Contact Email Addresses
  </h1>
</terra-dialog-header>

<mat-dialog-content [formGroup]="pageForm">
  <p class="info-message">
    Additional email addresses will receive some of the updates and will not have access to the investor portal.
  </p>
  <div formArrayName="emails" class="cov-form-grid single-column">

    <!-- Additional Email -->
    <div class="terra-form-field-wrapper" *ngFor="let emailControl of emailsFormArray.controls; let i = index;">
      <mat-form-field appearance="outline">
        <input id="txtAdditionalEmails" matInput [formControl]="emailControl" class="lo_sensitive" />
        <mat-error *ngIf="emailControl.hasError('email')"> Please enter a valid email address </mat-error>
      </mat-form-field>
      <button mat-icon-button aria-label="Remove email" (click)="emailsFormArray.length > 1 ? removeEmail(i) : clearEmail(i)" *ngIf="emailControl.value || emailsFormArray.length > 1" class="btn-remove-email">
        <mat-icon class="notranslate">delete</mat-icon>
      </button>

    </div>
  </div>

  <button mat-button (click)="addEmail()" [disabled]="emailsFormArray.controls.length >= MAX_EMAILS_NUMBER" class="btn-add-more">
    <mat-icon class="notranslate">add</mat-icon>
    Add more (up to {{MAX_EMAILS_NUMBER}} emails)
  </button>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" (click)="ok()" mat-flat-button color="primary" class="page-cta">OK</button>
  <button type="button" mat-button [mat-dialog-close]="undefined" class="tall-button btn-cancel">Cancel</button>
</mat-dialog-actions>
