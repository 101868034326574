import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ContactDialogState, ContactDialogStore } from './contact-dialog.store';

@Injectable()
export class ContactDialogQuery extends Query<ContactDialogState> {

  allState$ = this.select();
  contact$ = this.select(s => s.contact);
  investingEntities$ = this.select(s => s.contact.contactInvestingEntities);
  allContactReferrers$ = this.select(s => s.allContactReferrers);
  isUnsavedChanges$ = this.select(s => s.ui.isUnsavedChanges);
  isSaving$ = this.select(s => s.ui.isSaving);
  isSubmitted$ = this.select(s => s.ui.isSubmitted);

  constructor(protected store: ContactDialogStore) {
    super(store);
  }
}
