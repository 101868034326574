import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {BehaviorSubject, throwError, combineLatest, of, Observable} from 'rxjs';
import { shareReplay, switchMap, tap, catchError, map } from 'rxjs/operators';
import memo from 'memo-decorator';

import { GpInvestingEntityDataService } from 'src/app/services/gp/gp-investing-entity-data.service';
import { ReportStatus } from 'src/app/shared/enums/InvestingEntityReportStatus.enum';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import { LocationDetailsResponse } from 'src/app/shared/models/LocationDetailsResponse.model';
import { UtilsService } from 'src/app/services/utils.service';
import { PermissionService } from 'src/app/permission/permission.service';
import {TerraUtils} from '../../../../shared/TerraUtils';
import AccreditationStatus from 'src/app/shared/enums/AccreditationStatus.enum';

export interface InvestingEntityViewDialogContext {
  investingEntityId: number;
}

@Component({
  selector: 'terra-investing-entity-view-dialog',
  templateUrl: './investing-entity-view-dialog.component.html',
  styleUrls: ['./investing-entity-view-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestingEntityViewDialogComponent implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  // consts
  ReportStatus = ReportStatus;
  HoldingStatus = HoldingStatus;
  InvestingEntityType = InvestingEntityType;

  private isLoadingInvestingEntityDetails$ = new BehaviorSubject(true);
  investingEntity$ = this.gpInvestingEntityDataService.getById(this.context.investingEntityId).pipe(
    tap(_ => this.isLoadingInvestingEntityDetails$.next(false)),
    catchError(error => {
      this.isLoadingInvestingEntityDetails$.next(false);
      return throwError(error);
    }),
    shareReplay(1)
  );

  contributionsReportStatusFilter$ = new BehaviorSubject<ReportStatus>(ReportStatus.Active);
  isLoadingContributionsReport$ = new BehaviorSubject(false);
  contributionsReport$ = this.contributionsReportStatusFilter$.pipe(
    tap(_ => this.isLoadingContributionsReport$.next(true)),
    switchMap(status => this.gpInvestingEntityDataService.getContributionsReport(this.context.investingEntityId, status)),
    tap(_ => this.isLoadingContributionsReport$.next(false)),
    catchError(error => {
      this.isLoadingContributionsReport$.next(false);
      return throwError(error);
    }),
    shareReplay(1)
  );

  distributionsReportStatusFilter$ = new BehaviorSubject<ReportStatus>(ReportStatus.Active);
  isLoadingDistributionsReport$ = new BehaviorSubject(false);
  distributionsReport$ = this.distributionsReportStatusFilter$.pipe(
    tap(_ => this.isLoadingDistributionsReport$.next(true)),
    switchMap(status => this.gpInvestingEntityDataService.getDistributionsReport(this.context.investingEntityId, status)),
    tap(_ => this.isLoadingDistributionsReport$.next(false)),
    catchError(error => {
      this.isLoadingDistributionsReport$.next(false);
      return throwError(error);
    }),
    shareReplay(1)
  );

  isLoading$ = combineLatest([this.isLoadingInvestingEntityDetails$, this.isLoadingContributionsReport$]).pipe(
    map(([isLoadingInvestingEntityDetails, isLoadingContributionsReport]) => isLoadingInvestingEntityDetails || isLoadingContributionsReport),
    shareReplay(1)
  );

  isAccredited$ = this.investingEntity$.pipe(
    map(ie => ie.accreditation?.status === AccreditationStatus.Accredited),
    shareReplay(1)
  );

  contributionsReportDisplayedColumns = ['holdingName', 'date', 'investingEntityType', 'amountInvested'];
  distributionsReportDisplayedColumns = ['holdingName', 'date', 'investingEntityType', 'amountInvested'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public context: InvestingEntityViewDialogContext,
    private dialogRef: MatDialogRef<InvestingEntityViewDialogComponent>,
    private gpInvestingEntityDataService: GpInvestingEntityDataService,
    private utilsService: UtilsService,
    private permissionService: PermissionService
  ) {
    dialogRef.addPanelClass('terra-investing-entity-view-dialog');
  }

  ngOnInit() {
  }

  get accreditationDate$(): Observable<Date> {
    return this.investingEntity$.pipe(map(ie => TerraUtils.forceUtcToLocal(ie.accreditation?.accreditedDate)));
  }

  @memo()
  getFormattedAddress(location: LocationDetailsResponse) {
    return this.utilsService.getFormattedAddress(location, 'fullName');
  }

  filterContributionsByStatus(status: ReportStatus) {
    this.contributionsReportStatusFilter$.next(status);
  }

  filterDistributionsByStatus(status: ReportStatus) {
    this.distributionsReportStatusFilter$.next(status);
  }
}
