<div class="accreditation-container">
    <ng-container *ngIf="!onlyDatePicker">

        <div class="accreditation-description" *terraSystemFeatureFlag="FeatureFlagConsts.SystemFeatureFlagAccreditationOn">
            <div class="title">
                Accreditation <mat-icon class="notranslate">help</mat-icon>
            </div>
            <terra-chips-status [status]="accreditation?.status"
                [statusNamespace]="AccreditationStatus"></terra-chips-status>
            <ng-container *ngIf="!!accreditation && accreditation.status === AccreditationStatus.Requested">
                <a class="copy-request" [cdkCopyToClipboard]="linkRequest"
                    (click)="snackbarMessage('Request link', $event)">Copy Request Link</a>
            </ng-container>

            <ng-container
                *ngIf="!!accreditation && accreditation.status === AccreditationStatus.Accredited && accreditation.provider !== AccreditationProvider.Manual">
                <a class="download-certificate" *ngIf="!(downloadCertificateLoading$ | async)" (click)="downloadAccreditationCertificate()"
                terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.Hide">Download Certificate</a>
                <terra-loader *ngIf="downloadCertificateLoading$ | async"
                terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.Hide"></terra-loader>
            </ng-container>
        </div>
    </ng-container>
    <div class="accreditation-action">
        <div class="accreditationDate">
            <label for="txtAccreditationDate1" *ngIf="onlyDatePicker" class="text-input-label">Accreditation date
                (optional):</label>
            <mat-form-field appearance="outline" (click)="openConfirmDialogWhenLpAccredited()"
            terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick">
                <input matInput id="txtAccreditationDate1"
                terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick"
                    [ngClass]="{'accredited': isAccredited$ | async,
                    'not-accredited': !(isAccredited$ | async) }"
                    [matDatepicker]="accreditationDate" autocomplete="nope" [formControl]="accreditationDateControl"
                    (click)="openConfirmDialogWhenLpAccredited(); $event.stopPropagation()" class="lo_sensitive"
                    [max]="today" [min]="minDate" placeholder="Insert accreditation date"/>
                <mat-datepicker-toggle (click)="openConfirmDialogWhenLpAccredited(); $event.stopPropagation()" matSuffix
                terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick"
                    [for]="accreditationDate"></mat-datepicker-toggle>
                <mat-datepicker (click)="openConfirmDialogWhenLpAccredited(); $event.stopPropagation()"
                    #accreditationDate
                    disabled="false"
                    terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.PopupOnClick"></mat-datepicker>
            </mat-form-field>
        </div>
        <ng-container *terraSystemFeatureFlag="FeatureFlagConsts.SystemFeatureFlagAccreditationOn">
            <ng-container *ngIf="!onlyDatePicker">
                <strong terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.Hide">OR</strong>
                <button [disabled]="isLoadingAccreditationSend || disableAccreditationBtn" terraCheckPermissions [minimumPermissionLevel]="PermissionLevel.Editor" [noPermissionAction]="NoPermissionAction.Hide"
                    (click)="sendAccreditationRequest()" mat-flat-button color="primary" class="send-accreditation-btn">
                    <span>Send Automatic Request</span>
                    <terra-loader *ngIf="isLoadingAccreditationSend"></terra-loader>
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
