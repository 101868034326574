import {Injectable, ViewContainerRef} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {MatDialogConfig, MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Observable, throwError, of, timer} from 'rxjs';
import {shareReplay, switchMap, tap, catchError, map, take, filter, withLatestFrom} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {arrayRemove, arrayUpdate, arrayAdd} from '@datorama/akita';

import {ContactDataService} from 'src/app/services/gp/contact-data.service';
import {InvestorContactReqRes} from '../../models/investorContactReqRes.model';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import {CustomValidators} from 'src/app/shared/validators/custom.validators';
import {AlertDialogComponent} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {ContactDialogStore, ContactDialogState} from './state/contact-dialog.store';
import {ContactDialogQuery} from './state/contact-dialog.query';
import {ContactReferrerDataService} from 'src/app/services/gp/contact-referrer-data.service';
import {ContactReferrerReqRes} from 'src/app/shared/models/ContactReferrerReqRes.model';
import {
  EditContactInvestingEntityContext,
  EditInvestingEntityDialogComponent
} from './edit-investing-entity-dialog/edit-investing-entity-dialog.component';
import {LoggerService} from 'src/app/shared/errors/logger.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';
import {UserService} from '../../../../services/shared/user.service';
import AccreditationStatus from 'src/app/shared/enums/AccreditationStatus.enum';
import { AccreditationDto, AccreditationProvider } from 'src/app/shared/models/AccreditationDto.model';

@Injectable()
export class InvestorContactDetailService extends OnDestroyMixin {

  viewContainerRef: ViewContainerRef;

  dialogForm: UntypedFormGroup;
  readonly forbiddenNameCharacters = TerraUtils.consts.validators.FORBIDDEN_CHARACTERS_IN_NAME;
  // Tab 1 form
  get contactDetailsForm(): UntypedFormGroup {
    return this.dialogForm.get('contactDetails') as UntypedFormGroup;
  }

  private _isGeneralServerError$ = new BehaviorSubject(false);

  isGeneralServerError$ = this._isGeneralServerError$.pipe(shareReplay(1));

  generalServerErrorMessage$ = new BehaviorSubject<string>(null);

  contactDetails$ = this.query.contact$;

  private contactTagsResponse$ = this.contactDataService.getContactTags().pipe(shareReplay(1));

  allTags$ = this.contactTagsResponse$.pipe(map(response => response.tags.map(tagIdAndLabel => tagIdAndLabel.value)), shareReplay(1));

  mostUsedTags$ = this.contactTagsResponse$.pipe(map(response => response.mostUsedTags.map(tagWithCount => tagWithCount.key)), shareReplay(1));

  constructor(
    private logger: LoggerService,
    private store: ContactDialogStore,
    private query: ContactDialogQuery,
    private contactDataService: ContactDataService,
    private contactReferrerDataService: ContactReferrerDataService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    super();
    this.generateForm();
    this.initContactReferrersListForFilter();
  }

  initializeDialogForCreateMode() {
    this.store.update(state => ({...state, contact: new InvestorContactReqRes()}));
    this.raiseFlagWhenUnsavedChanges();
  }

  initialDialogForEditMode(contactId: number) {
    this.store.setLoading(true);
    this.contactDataService.getById(contactId)
      .pipe(
        untilComponentDestroyed(this),
        tap(contact => {
          this.setContactInState(contact);
          this.populateForm(contact);
          this.raiseFlagWhenUnsavedChanges();
          this.store.setLoading(false);
        }),
        catchError(error => {
          this.logger.error(`Error when initializing contact dialog for edit mode. contactId: ${contactId}`, error);
          this.showErrorAlertDialog();
          this.store.setLoading(false);
          return throwError(error);
        })
      ).subscribe();
  }

  /** Create or Update (in case a contactId was provided) the contact and his investing entities. */
  save(): Observable<InvestorContactReqRes> {
    this.setIsSubmitted();
    this._isGeneralServerError$.next(false);

    TerraUtils.validateAllFormFields(this.dialogForm);

    if (!this.dialogForm.valid) {
      return of(null);
    }
    this.setIsSaving(true);
    return this.contactDetails$
      .pipe(
        untilComponentDestroyed(this),
        take(1),
        map(contactDetails =>
          this.generateSubmitModel(contactDetails.id, contactDetails.contactTags, contactDetails.contactInvestingEntities)),
        switchMap(model => {
          if (model.id) {
            return this.contactDataService.update(model.id, model);
          } else {
            return this.contactDataService.create(model);
          }
        }),
        catchError(error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else {
            // email already exists for another contact of this GP, this is not allowed
            if (ErrorMatcher.isError(error, ErrorType.AlreadyExistsException)) {
              this.generalServerErrorMessage$.next('A contact with this Email address already exists.');
            } else {
              this.generalServerErrorMessage$.next(TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK);
            }
            this._isGeneralServerError$.next(true);
          }
          this.setIsSaving(false);
          return throwError(error);
        }),
        tap(() => this.setIsSaving(false))
      );
  }

  updateTabIndex(tabIndex: number) {
    this.store.update(state => ({...state, ui: {...state.ui, activeTabIndex: tabIndex}}));
  }

  //#region Contact Tags methods
  addContactTag(tag: string) {
    tag = tag.replace(/<.*?>/g, '').trim().toLowerCase();
    if (this.isAllowedToAddThisTag(tag)) {
      this.store.update(state => {
        return {
          ...state,
          contact: {
            ...state.contact,
            contactTags: arrayAdd(state.contact.contactTags, tag)
          }
        };
      });
      this.setChangesMade();
    }
  }

  removeContactTag(tag: string): void {
    this.store.update(state => {
      return {
        ...state,
        contact: {
          ...state.contact,
          contactTags: arrayRemove(state.contact.contactTags, t => t === tag)
        }
      };
    });
    this.setChangesMade();
  }

  //#endregion

  //#region Investing Entity methods
  addInvestingEntity(investingEntity: InvestingEntityReqRes) {
    this.store.update(state => {
      return {
        ...state,
        contact: {
          ...state.contact,
          contactInvestingEntities: arrayAdd(state.contact.contactInvestingEntities, investingEntity)
        }
      } as ContactDialogState;
    });

    this.setChangesMade();
  }

  updateInvestingEntity(investingEntityToEdit: InvestingEntityReqRes, investingEntity: InvestingEntityReqRes) {
    this.store.update(state => {
      return {
        ...state,
        contact: {
          ...state.contact,
          contactInvestingEntities: arrayUpdate(state.contact.contactInvestingEntities, ie => ie === investingEntityToEdit, investingEntity)
        }
      };
    });
    this.setChangesMade();
  }

  deleteInvestingEntity(investingEntity: InvestingEntityReqRes) {
    this.store.update(state => {
      return {
        ...state,
        contact: {
          ...state.contact,
          contactInvestingEntities: arrayRemove(state.contact.contactInvestingEntities, ie => ie === investingEntity)
        }
      };
    });
    this.setChangesMade();
  }

  /** This will work for both existing (saved) investing entities, and new ones that are not yet save, and don't have an id. */
  openInvestingEntityDialogInEditMode(investingEntity: InvestingEntityReqRes) {
    this.openInvestingEntityDialogCreateOrEdit(investingEntity);
  }

  /** This will only work for existing (saved) investing entities */
  openInvstingEntityDialogByIdInEditMode(investingEntityId: number) {
    const investingEntity$ = this.query.select(state => state.contact.contactInvestingEntities.find(ie => ie.id === investingEntityId));
    investingEntity$.pipe(
      filter(investingEntity => !!investingEntity),
      take(1),
      untilComponentDestroyed(this)
    ).subscribe(investingEntity => {
      this.openInvestingEntityDialogInEditMode(investingEntity);
    });
  }

  openCreateInvestingEntityDialog() {
    this.openInvestingEntityDialogCreateOrEdit();
  }

  /** If an ivesting entity object is provided, the dialog will be opened in edit mode. Otherwise, it will be in 'Create' mode. */
  private openInvestingEntityDialogCreateOrEdit(investingEntityToEdit: InvestingEntityReqRes = null) {
    const isEdit = investingEntityToEdit !== null;
    this.query.contact$.pipe(
      take(1),
      switchMap(contactDetails => {
        const dialogConfig = new MatDialogConfig<EditContactInvestingEntityContext>();
        dialogConfig.viewContainerRef = this.viewContainerRef;
        dialogConfig.data = {
          id: isEdit ? investingEntityToEdit.id : null,
          investingEntity: investingEntityToEdit,
          contactLocationDetails: contactDetails ? contactDetails.contactLocation : null,
          contactFullName: contactDetails ? TerraUtils.getContactFullName(contactDetails) : null
        };
        return this.dialog.open<any, any, InvestingEntityReqRes>(EditInvestingEntityDialogComponent, dialogConfig).afterClosed();
      })
    )
      .subscribe(investingEntity => {
          if (investingEntity) {
            if (isEdit) {
              this.updateInvestingEntity(investingEntityToEdit, investingEntity);
            } else {
              this.addInvestingEntity(investingEntity);
            }
          }
        }
      );
  }

  //#endregion

  addContactReferrer(contactReferrer: ContactReferrerReqRes) {
    return this.contactReferrerDataService.create(contactReferrer).pipe(
      tap(createdContactReferrer => {
        this.store.update(state => {
          return {
            ...state,
            allContactReferrers: arrayAdd(state.allContactReferrers, createdContactReferrer)
          };
        });
      }),
      tap(createdContactReferrer => {
        const contactReferrerControl = this.dialogForm.get('contactDetails.contactReferrerId');
        contactReferrerControl.setValue(createdContactReferrer.id);
        contactReferrerControl.markAsDirty();
        contactReferrerControl.updateValueAndValidity();
      }));
  }

  private generateForm() {
    // The initial values are set to the value that will be in the inputs when the user clears them. This will allow dirty checking to work.
    // since when the user clears a text input, it's value becomes '' (not null), and when clearing the phone input it becomes null, these will be the initial values.
    this.dialogForm = this.fb.group({
      contactDetails: this.fb.group({
        firstName: ['', Validators.compose([Validators.required, Validators.maxLength(TerraUtils.consts.validators.FIRSTNAME_LENGTH),
          CustomValidators.containsForbiddenCharacterValidator(this.forbiddenNameCharacters)])],
        lastName: ['', Validators.compose([Validators.required, Validators.maxLength(TerraUtils.consts.validators.LASTNAME_LENGTH),
          CustomValidators.containsForbiddenCharacterValidator(this.forbiddenNameCharacters)])],
        middleName: ['', Validators.compose([Validators.maxLength(TerraUtils.consts.validators.LASTNAME_LENGTH),
          CustomValidators.containsForbiddenCharacterValidator(this.forbiddenNameCharacters)])],
        email: ['', [Validators.required, CustomValidators.EmailWithSpaces]],
        additionalEmails: [],
        phone: [null, Validators.compose([Validators.maxLength(TerraUtils.consts.validators.PHONE_MAX_LENGTH)])],
        personalRemarks: ['', Validators.compose([Validators.maxLength(TerraUtils.consts.validators.GENERAL_TEXT_LENGTH)])],
        contactTagsTerm: [''],
        contactReferrerId: null,
        contactLocation: null,
        isFamilyOffice: null,
        accreditationDate: ['']
      })
    });
  }

  private populateForm(contact: InvestorContactReqRes) {
    this.dialogForm.patchValue({
      contactDetails: {
        firstName: contact.firstName,
        lastName: contact.lastName,
        middleName: contact.middleName,
        email: contact.email,
        additionalEmails: contact.additionalEmails,
        phone: contact.phone,
        personalRemarks: contact.personalRemarks,
        contactReferrerId: contact.contactReferrerId,
        isFamilyOffice: contact.isFamilyOffice,
        contactLocation: {address: contact.contactLocation, street2Name: contact.contactLocation?.street2Name}
      }
    });
  }

  private showErrorAlertDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      title: `An error has occurred`,
      description: `An error has occurred, please try again or contact support.`,
      actionLabel: `OK`
    };
    this.dialog.open(AlertDialogComponent, dialogConfig);
  }

  private generateSubmitModel(contactId: number, selectedContactTags: string[], investingEntities: InvestingEntityReqRes[]): InvestorContactReqRes {
    const formValues = this.dialogForm.value.contactDetails;
    const model = new InvestorContactReqRes();
    model.id = contactId;
    model.firstName = formValues.firstName;
    model.lastName = formValues.lastName;
    model.middleName = formValues.middleName;
    model.email = formValues.email;
    model.additionalEmails = formValues.additionalEmails;
    model.personalRemarks = formValues.personalRemarks;
    model.phone = formValues.phone;
    model.contactTags = selectedContactTags;
    model.contactLocation = {...formValues.contactLocation.address, street2Name: formValues.contactLocation.street2Name} as LocationDetailsResponse;
    model.contactInvestingEntities = investingEntities;
    model.contactReferrerId = formValues.contactReferrerId;
    model.isFamilyOffice = formValues.isFamilyOffice;
    model.accreditationDate = TerraUtils.forceUtc(formValues.accreditationDate);

    if (!!formValues.accreditationDate && !!model.accreditation) {
      model.accreditation.provider = AccreditationProvider.Manual;
      model.accreditation.accreditedDate = TerraUtils.forceUtc(formValues.accreditationDate);
      model.accreditation.status = AccreditationStatus.Accredited;
      model.accreditation.accreditationRequire = false;
    }

    if (!!formValues.accreditationDate && !model.accreditation) {
      model.accreditation = new AccreditationDto();
      model.accreditation.provider = AccreditationProvider.Manual;
      model.accreditation.accreditedDate = TerraUtils.forceUtc(formValues.accreditationDate);
      model.accreditation.status = AccreditationStatus.Accredited;
      model.accreditation.accreditationRequire = false;
    }
    return model;
  }

  private raiseFlagWhenUnsavedChanges() {
    this.dialogForm.valueChanges.pipe(
      untilComponentDestroyed(this),
      map(value => {
        return this.dialogForm.dirty;
      }),
      filter(isDirty => isDirty),
      take(1),
      tap(isDirty => {
        this.setChangesMade();
      }),
    )
      .subscribe(
        isDirty => {
        },
        error => {
        }
      );
  }

  private initContactReferrersListForFilter() {
    this.contactReferrerDataService.getAll()
      .pipe(
        untilComponentDestroyed(this),
        tap(allReferrers => {
            this.store.update(state => {
              return {
                ...state,
                allContactReferrers: [...allReferrers]
              };
            });
          }
        )).subscribe(
      () => {
      },
      error => this.logger.error('investor-contact-details.service.ts => initContactReferrersListForFilter()',error)
    );
  }

  private setContactInState(contact: InvestorContactReqRes) {
    this.store.update(state => ({
      ...state,
      contact,
    } as ContactDialogState));
  }

  private setChangesMade() {
    timer(0).pipe(
      tap(x => {
        this.store.update(state => ({...state, ui: {...state.ui, isUnsavedChanges: true}}));
      })
    ).subscribe();
  }

  private setIsSaving(isSaving: boolean) {
    this.store.update(state => ({...state, ui: {...state.ui, isSaving}}));
  }

  private setIsSubmitted() {
    this.store.update(state => ({...state, ui: {...state.ui, isSubmitted: true}}));
  }

  private isAllowedToAddThisTag(tag: string) {
    return !this.isTagAlreadyExists(tag) && this.query.getValue().contact.contactTags.length < 20;
  }

  private isTagAlreadyExists(tag: string) {
    const tags = this.query.getValue().contact.contactTags;
    return tags && tags.find(t => t === tag);
  }
}
