import { Injectable } from '@angular/core';
import { Observable, filter, map, shareReplay, tap, withLatestFrom } from 'rxjs';
import { AccreditationDataService } from 'src/app/services/shared/accreditation-data.service';
import { GeneralSettingsService } from 'src/app/services/shared/general-settings.service';
import { UserService } from 'src/app/services/shared/user.service';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { GeneralSettingKeys } from 'src/app/shared/consts/GeneralSettingKeys.consts';
import { AccreditationDto } from 'src/app/shared/models/AccreditationDto.model';

@Injectable()
export class AccreditationService {

  public enableToSendAccreditationRequest$ = this.userService.accountDetails$
  .pipe(
    filter(accountDetails => !!accountDetails && accountDetails.isAgent),
    map(accountDetails => accountDetails.organizationDetails),
    map(organizationDetails => organizationDetails.maxOfAccreditationRequest > 0),
    shareReplay(1)
  );

  public reachingLimitToSendAccreditationRequest$ = this.userService.accountDetails$
  .pipe(
    filter(accountDetails => !!accountDetails && accountDetails.isAgent),
    map(accountDetails => accountDetails.organizationDetails),
    map(organizationDetails => organizationDetails.countOfAccreditationRequest >= organizationDetails.maxOfAccreditationRequest),
    shareReplay(1)
  );

  public accreditationPrice$ = this.generalSettingsService.GetByKey(GeneralSettingKeys.AccreditationPrice)
  .pipe(
    shareReplay(1)
  );

  constructor(private userService: UserService,
    private accreditationDataService: AccreditationDataService,
    private generalSettingsService: GeneralSettingsService) { }

  public isAccreditedInvestor$(accreditedDate: Observable<Date>): Observable<boolean> {
    return accreditedDate.pipe(
      withLatestFrom(this.userService.accountDetails$),
      shareReplay(1),
      map(([accreditationDate, account]) => {
        if (!accreditationDate) {
          return false;
        }

        return TerraUtils.daysBetween(accreditationDate, new Date()) < account.organizationDetails.maxAccreditationDays;
      }),
      shareReplay(1)
    );
  }

  public sendAccreditationRequestToLp(model:AccreditationDto):Observable<AccreditationDto>{
    return this.accreditationDataService.sendAccreditationRequestToLp(model).pipe(
      tap(_ => this.userService.refreshedClientDetails$.next(null))
    )
  }

}
