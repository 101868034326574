<terra-dialog-header>
  <h1 class="lo_sensitive" *ngIf="investingEntity$ | async as investingEntity">
    Investing entity:
    <ng-container *ngIf="allowInvestorName$ | async">
      {{investingEntity.name}}
    </ng-container>
    <ng-container *ngIf="!(allowInvestorName$ | async)">
      {{investingEntity.nickname}}
    </ng-container>
  </h1>
</terra-dialog-header>

<mat-dialog-content>

  <ng-container *ngIf="investingEntity$ | async as investingEntity">
    <!-- Basic details about the investing entity -->
    <section class="section">
      <ul class="details-list">
        <li class="details-list-item">
          <label>Name: </label>
          <span class="lo_sensitive">
            <ng-container *ngIf="allowInvestorName$ | async">
              {{investingEntity.name}}
            </ng-container>
            <ng-container *ngIf="!(allowInvestorName$ | async)">
              {{investingEntity.nickname}}
            </ng-container>
          </span>
        </li>
        <li class="details-list-item">
          <label>Type: </label>
          <span>{{investingEntity.investingEntityType | enumString : InvestingEntityType }}</span>
        </li>
        <li class="details-list-item" *ngIf="investingEntity.tin">
          <label>Tax identification number (TIN): </label>
          <span class="lo_sensitive">{{investingEntity.tin}}</span>
        </li>
        <li class="details-list-item" *ngIf="investingEntity.accountingExternalId">
          <label>Accounting ID: </label>
          <span class="lo_sensitive">{{investingEntity.accountingExternalId}}</span>
        </li>
        <li class="details-list-item">
          <label>Accreditation date: </label>
          <span class="lo_sensitive" [ngClass]="{'accredited': (isAccredited$ | async), 'not-accredited': !(isAccredited$ | async)}">
            {{((accreditationDate$ | async) | date) ?? 'None'}}
          </span>
        </li>
        <li class="details-list-item"><label>Address: <mat-icon class="tooltip-icon notranslate" color="accent"
              matTooltip="Using the contact's address" matTooltipClass="terra-tooltip">
              announcement</mat-icon></label>
          <span *ngIf="investingEntity.useContactLocation else ieLocation">
            <ng-container
              *ngIf="getFormattedAddress(investingEntity.contact?.contactLocation) as locationString else contactLocationNotDefined">
              {{locationString}}
            </ng-container>
            <ng-template #contactLocationNotDefined>
              - Not specified
            </ng-template>
          </span>
          <ng-template #ieLocation>
            <span *ngIf="investingEntity.investingEntityLocationId">
              {{ getFormattedAddress(investingEntity.investingEntityLocation)}}
            </span>
            <span *ngIf="!investingEntity.investingEntityLocationId">
              Not specified
            </span>
          </ng-template>
        </li>
      </ul>
    </section>

    <section class="section" *ngIf="investingEntity.remarks">
      <h2 class="section-title">Remarks</h2>
      <p class="lo_sensitive">{{investingEntity.remarks}}</p>
    </section>

  </ng-container>

  <!-- Contributions report -->
  <section class="section investments-report-wrapper" *ngIf="contributionsReport$ | async as contributionsReport">
    <h2 class="section-title">Investments</h2>
    <!-- Status selector -->
    <div class="status-filter-buttons" *ngIf="contributionsReportStatusFilter$ | async as selectedStatus">
      <div (click)="filterContributionsByStatus(ReportStatus.Active)" role="button" class="filter-button" matRipple
        [class.active]="selectedStatus === ReportStatus.Active">
        <mat-icon class="notranslate filter-icon">check_circle_outline</mat-icon>
        Active
      </div>
      <div (click)="filterContributionsByStatus(ReportStatus.Archived)" role="button" class="filter-button" matRipple
        [class.active]="selectedStatus === ReportStatus.Archived">
        <mat-icon class="notranslate filter-icon">snippet_folder</mat-icon>
        Archived
      </div>
      <div (click)="filterContributionsByStatus(ReportStatus.Declined)" role="button" class="filter-button" matRipple
        [class.active]="selectedStatus === ReportStatus.Declined">
        <mat-icon class="notranslate filter-icon">block</mat-icon>
        Declined opportunities
      </div>
    </div>

    <!-- Data grid -->
    <table mat-table [dataSource]="contributionsReport" *ngIf="!(isLoading$ | async)"
      class="cov-basic-table table-narrow-sides mat-elevation-z0">

      <ng-container matColumnDef="holdingName">
        <th mat-header-cell *matHeaderCellDef class="name-table-header">Holding name</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{element.holdingName}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="name-table-header"> Last contribution<br>date</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{element.date | date }} </td>
      </ng-container>

      <ng-container matColumnDef="investingEntityType">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status | enumString: HoldingStatus}} </td>
      </ng-container>

      <ng-container matColumnDef="amountInvested">
        <th mat-header-cell *matHeaderCellDef>Amount invested</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive">
          {{ element.currencySymbol}}{{element.amountInvested | terraNumber }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="contributionsReportDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: contributionsReportDisplayedColumns" class="value-row"></tr>
    </table>

    <!-- Empty state -->
    <div class="empty-state" *ngIf="!(isLoading$ | async) && contributionsReport.length === 0">
      <h2>No items to display.</h2>
    </div>
  </section>

  <terra-loader *ngIf="isLoading$ | async"></terra-loader>

  <!-- Distributions report -->
  <section class="section investments-report-wrapper" *ngIf="distributionsReport$ | async as distributionsReport">
    <h2 class="section-title" *ngIf="!(isLoading$ | async)">Distributions</h2>

    <!-- Data grid -->
    <table mat-table [dataSource]="distributionsReport" *ngIf="!(isLoading$ | async)"
      class="cov-basic-table table-narrow-sides mat-elevation-z0">

      <ng-container matColumnDef="holdingName">
        <th mat-header-cell *matHeaderCellDef class="name-table-header">Holding name</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{element.holdingName}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="name-table-header">Date</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{element.date | date }} </td>
      </ng-container>

      <ng-container matColumnDef="investingEntityType">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status | enumString: HoldingStatus}} </td>
      </ng-container>

      <ng-container matColumnDef="amountInvested">
        <th mat-header-cell *matHeaderCellDef>Amount received</th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> {{element.amountPerCurrency }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="distributionsReportDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: distributionsReportDisplayedColumns" class="value-row"></tr>
    </table>

    <!-- Empty state -->
    <div class="empty-state" *ngIf="!(isLoading$ | async) && distributionsReport.length === 0">
      <h2>No items to display.</h2>
    </div>
  </section>

</mat-dialog-content>
