import {ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {finalize, map, startWith, take} from 'rxjs/operators';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {BehaviorSubject, Observable} from 'rxjs';

import {ClientBankAccountReqRes} from 'src/app/dashboard/models/bankAccount.model';
import {GpBankAccountDataService} from 'src/app/services/gp/gp-bank-account-data.service';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {enterAnimation, fadeInOut} from 'src/app/shared/animations';
import {TerraUtils} from 'src/app/shared/TerraUtils';
import {BeneficiaryEntityType} from 'src/app/shared/enums/BeneficiaryEntityType.enum';
import {CountryModel} from 'src/app/shared/models/CountryModel';
import {ResourceService} from 'src/app/services/resource.service';
import {DialogWithFormBase} from 'src/app/shared/types/DialogWithFormBase';
import {DialogService} from 'src/app/services/dialog.service';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {CurrencyModel} from 'src/app/shared/models/CurrencyModel';
import {EditBankAccountContext} from './EditBankAccountContext.model';
import {AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';
import {UserService} from 'src/app/services/shared/user.service';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';
import {PermissionService} from 'src/app/permission/permission.service';
import {PaymentType} from 'src/app/shared/enums/payment-type.enum';

@Component({
  selector: 'terra-create-bank-account',
  templateUrl: './edit-bank-account.component.html',
  styleUrls: ['./edit-bank-account.component.scss'],
  animations: [fadeInOut, enterAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBankAccountComponent extends DialogWithFormBase implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;

  @HostBinding('class.read-only-mode') get readOnlyMode(): boolean {
    return this.bankAccountDetails && this.bankAccountDetails.id > 0 && !this.bankAccountDetails.isModifiable;
  }

  BeneficiaryEntityType = BeneficiaryEntityType;
  pageForm: UntypedFormGroup;
  isSubmitted = false;
  showInternalLoader$ = new BehaviorSubject<boolean>(false);

  bankAccountDetails: ClientBankAccountReqRes;
  bankAccountId = 0;
  isGeneralServerError$ = new BehaviorSubject<boolean>(false);
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;
  allCountries = this.resourceService.allCountries;

  isIgnoreReadOnlyBeneficiaryEntityType = false;
  isIgnoreReadOnlyBeneficiaryTin = false;
  isAnOrganizationAccountDefault = true;
  isAutoFilled = false;
  filteredInvestors$: Observable<InvestingEntityReqRes[]>;
  internalFilteredInvestors: InvestingEntityReqRes[];

  addressFormSettings = new AddressFormSettings();

  enablePreferredCurrency = false;
  // isRecommendedCurrencySelected$ = new BehaviorSubject(false);
  investingEntity: InvestingEntityReqRes = null;

  constructor(
    private fb: UntypedFormBuilder,
    private gpBankAccountDataService: GpBankAccountDataService,
    private resourceService: ResourceService,
    private dialogRef: MatDialogRef<EditBankAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditBankAccountContext,
    private dialogService: DialogService,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private utilsService: UtilsService,
    private permissionService: PermissionService
  ) {
    super();
  }

  ngOnInit() {
    this.bankAccountDetails = new ClientBankAccountReqRes();
    this.bankAccountId = 0;

    if (this.data) {
      if (this.data.id) {
        this.bankAccountId = this.data.id;
      }
      if (this.data.enablePreferredCurrency) {
        this.enablePreferredCurrency = this.data.enablePreferredCurrency;
      }
      if (this.data.investingEntity) {
        this.investingEntity = this.data.investingEntity;
      }
      if (this.data.isAnOrganizationAccountDefault !== null) {
        this.isAnOrganizationAccountDefault = this.data.isAnOrganizationAccountDefault;
      }
    }

    this.generateForm();
    this.subscribeToIsOrganizationChange();

    if (this.bankAccountId > 0) {
      this.populateForm(this.bankAccountId);
    }
  }

  generateForm() {
    this.pageForm = this.fb.group({
      nickname: [null, Validators.required],
      holderFullName: [null, Validators.required],
      isAnOrganizationAccount: [(this.isAnOrganizationAccountDefault || false)],
      beneficiaryEntityType: [null, this.isAnOrganizationAccountDefault ? Validators.required : null],
      beneficiaryTin: [null, this.isAnOrganizationAccountDefault ? Validators.required : null],
      investor: [null, this.data && this.data.investingEntitiesList ? Validators.required : null],
      bankDetails: {},
      holderLocationDetails: {}
    });

    if (this.investingEntity) {
      this.pageForm.get('beneficiaryEntityType').setValue(this.investingEntity.investingEntityType);
      this.pageForm.get('beneficiaryTin').setValue(this.investingEntity.tin);
    }

    if (this.data && this.data.investingEntitiesList) {
      this.filteredInvestors$ = this.pageForm.get('investor').valueChanges.pipe(startWith(''), map(value => this.filterInvestors(value)));
    }
  }

  populateForm(bankAccountId: number) {
    this.showInternalLoader$.next(true);

    this.gpBankAccountDataService
      .getById(bankAccountId)
      .pipe(
        finalize(() => {
          this.showInternalLoader$.next(false);
        })
      )
      .subscribe(
        bankAccount => {
          this.bankAccountDetails = bankAccount;

          this.pageForm.patchValue({
            nickname: bankAccount.nickname,
            holderFullName: bankAccount.holderFullName,
            isAnOrganizationAccount: bankAccount.isAnOrganizationAccount,
            beneficiaryEntityType: bankAccount.beneficiaryEntityType !== 0 ? bankAccount.beneficiaryEntityType : null,
            beneficiaryTin: bankAccount.beneficiaryTin
          });

          // Disable fields/forms if read only.
          // Child forms may have further special handling inside the relevant component when disabled, such as the bank details form (in client-bank-account).
          if (this.readOnlyMode) {
            this.pageForm.get('holderFullName').disable();
            this.pageForm.get('isAnOrganizationAccount').disable();
            this.pageForm.get('bankDetails').disable();
            this.pageForm.get('holderLocationDetails').disable();
            // If we're missing any required tax withholding data, keep the fields enabled
            if (this.isCollectWitholdingTaxData()) {
              if (this.pageForm.get('beneficiaryEntityType').value) {
                this.pageForm.get('beneficiaryEntityType').disable();
                this.isIgnoreReadOnlyBeneficiaryEntityType = false;
              } else {
                this.isIgnoreReadOnlyBeneficiaryEntityType = true;
              }
              if (this.pageForm.get('beneficiaryTin').value) {
                this.pageForm.get('beneficiaryTin').disable();
                this.isIgnoreReadOnlyBeneficiaryTin = false;
              } else {
                this.isIgnoreReadOnlyBeneficiaryTin = true;
              }
            }
            this.pageForm.markAllAsTouched();
          }
        },
        err => {
          console.log('Error', err);
        }
      );
  }

  save() {
    this.isSubmitted = true;
    this.isGeneralServerError$.next(false);
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();

    if (!this.pageForm.valid) {
      return;
    }

    this.showInternalLoader$.next(true);
    const model: ClientBankAccountReqRes = this.generateSubmitModel();

    const isEditMode = this.bankAccountId > 0;

    const editOrCreateResponse$ = isEditMode ?
      this.gpBankAccountDataService.update(this.bankAccountId, model) :
      this.gpBankAccountDataService.create(model);

    editOrCreateResponse$.subscribe(bankAccountDetails => {
      this.showInternalLoader$.next(false);
      this.dialogRef.close(bankAccountDetails);
      this.userService.getClientDetails().pipe(take(1)).subscribe(clientDetails => {
        if (isEditMode) {
          this.telemetryService.create({
            eventID: '602',
            eventTitle: 'EDIT BANK ACCOUNT',
            organizationID: clientDetails.organizationDetails.id,
            additional: {
              bankAccountID: bankAccountDetails.id
            }
          }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
        } else {
          this.telemetryService.create({
            eventID: '601',
            eventTitle: 'ADD BANK ACCOUNT',
            organizationID: clientDetails.organizationDetails.id,
            additional: {
              bankAccountID: bankAccountDetails.id
            }
          }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
        }
      });
    }, error => {
      if (error instanceof BaseResponseDto) {
        this.utilsService.alertErrorMessage(error);
      } else {
        this.isGeneralServerError$.next(true);
      }
      this.showInternalLoader$.next(false);
    });
  }

  generateSubmitModel(): ClientBankAccountReqRes {
    const formValues = this.readOnlyMode ? this.pageForm.getRawValue() : this.pageForm.value;
    const model = new ClientBankAccountReqRes();
    model.id = this.bankAccountId;
    model.holderLocationDetails = formValues.holderLocationDetails as LocationDetailsResponse;
    model.nickname = formValues.nickname;
    model.holderFullName = formValues.holderFullName;
    model.isAnOrganizationAccount = formValues.isAnOrganizationAccount;
    model.accountNumber = formValues.bankDetails.accountNumber;
    model.bankBranchCode = formValues.bankDetails.bankBranchCode;
    model.bankBranchName = formValues.bankDetails.bankBranchName;
    model.bankCode = formValues.bankDetails.bankCode;
    model.bankName = formValues.bankDetails.bankName;
    model.bsb = formValues.bankDetails.bsb;
    model.canadaInstitutionNumber = formValues.bankDetails.canadaInstitutionNumber;
    model.canadaTransitNumber = formValues.bankDetails.canadaTransitNumber;
    model.countryId = formValues.bankDetails.countryId;
    model.iban = formValues.bankDetails.iban;
    model.ifsc = formValues.bankDetails.ifsc;
    model.swift = formValues.bankDetails.swift;
    model.paymentType = formValues.bankDetails.paymentType;
    model.ukSortCode = formValues.bankDetails.ukSortCode;
    model.beneficiaryEntityType = this.isCollectWitholdingTaxData() ? formValues.beneficiaryEntityType : 0;
    model.beneficiaryTin = this.isCollectWitholdingTaxData() ? formValues.beneficiaryTin : null;
    model.preferredCurrencyId = formValues.bankDetails.preferredCurrency ? formValues.bankDetails.preferredCurrency.id : null;
    model.memo = formValues.bankDetails.memo;
    model.checkingOrSavingsType = formValues.bankDetails.checkingOrSavingsType;

    if (this.investingEntity) {
      model.investingEntityId = this.investingEntity.id;
    } else if (formValues.investor) {
      model.investingEntityId = formValues.investor;
    }

    if (model.paymentType === PaymentType.Ach) {
      model.usabaRoutingNumber = formValues.bankDetails.usabaRoutingNumber;
      model.fedWireRoutingNumber = null;
    }

    if (model.paymentType === PaymentType.Wire) {
      model.fedWireRoutingNumber = formValues.bankDetails.frn;
      model.usabaRoutingNumber = null;
    }

    return model;
  }

  isCollectWitholdingTaxData(): boolean {
    return this.pageForm.get('isAnOrganizationAccount').value;
  }

  subscribeToIsOrganizationChange() {
    this.pageForm.controls.isAnOrganizationAccount.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(isOrganization => {
      if (this.isCollectWitholdingTaxData()) {
        this.pageForm.controls.beneficiaryTin.setValidators([Validators.required]);
        this.pageForm.controls.beneficiaryEntityType.setValidators([Validators.required]);

      } else {
        this.pageForm.controls.beneficiaryTin.setValidators([]);
        this.pageForm.controls.beneficiaryTin.setValue(null);
        this.pageForm.controls.beneficiaryTin.markAsUntouched();
        this.pageForm.controls.beneficiaryEntityType.setValidators([]);
        this.pageForm.controls.beneficiaryEntityType.setValue(null);
        this.pageForm.controls.beneficiaryEntityType.markAsUntouched();
      }
      this.pageForm.controls.beneficiaryTin.updateValueAndValidity();
      this.pageForm.controls.beneficiaryEntityType.updateValueAndValidity();
    });
  }

  getAddressCountry(): CountryModel {
    const locationDetailsForm = this.pageForm.get('holderLocationDetails') as UntypedFormGroup;
    if (!locationDetailsForm.get('countryId') || !locationDetailsForm.get('countryId').value) {
      return null;
    }
    const addressCountryId = this.pageForm.get(['holderLocationDetails', 'countryId']).value;
    return this.allCountries ? this.allCountries.find(c => c.id === addressCountryId) : null;
  }

  currencyLearnMore(isRecommdendedCurrencySelected: boolean, recommendedCurrency: CurrencyModel, selectedCurrency: CurrencyModel) {
    let longText = '';
    if (!isRecommdendedCurrencySelected) {
      longText = `
      <p>Choosing to distribute funds to your investors in the local currency of their country usually gives the best value for money. It also means Covercy can use a local (and faster) payment in that country which means funds arrive sooner.</p>
      <p>In case you choose a currency which is not the local currency in that country, Covercy will use SWIFT to send your funds. This means that your transfer will take longer and be more expensive, as investors' banks may also deduct their handling fees when receiving the funds, or once your investors choose to convert to their local currency.</p>
      <p><strong>We highly recommend you to choose ${recommendedCurrency.iso} instead of ${selectedCurrency.iso}</strong></p>
      `;
    } else {
      longText = `
      <p>Covercy offers the best exchange rates and guarantees the exact amount received in ${recommendedCurrency.iso} in the destination bank account.</p>
      <p>With Covercy, there's no room for hidden bank surcharges or additional charges that you don't see here.</p>
      <p>By delivering funds to your investors in their country's local currency, you save them the hassle and fees of converting the funds themselves.</p>`
      // Compare Covercy exchange rates and fees with some of the biggest money transfer services around.
      ;
    }
    this.dialogService.alertDialog({
      title: 'Recommended currency for distribution',
      description: longText
    } as AlertDialogParams);
  }

  setAutofillState(state: boolean) {
    this.isAutoFilled = state;
  }

  isError() {
    return this.pageForm.get('investor').hasError('required') &&
      (this.pageForm.get('investor').dirty || this.pageForm.get('investor').touched);
  }

  displayFn(value?: number): string | undefined {
    // Get the view value from the options
    if (value) {
      const option = this['options']['_results'].find(x => x.value === value);
      return option ? option.viewValue : undefined;
    } else {
      return undefined;
    }
  }

  investorTrackByFn(index, item: InvestingEntityReqRes) {
    return item.id; // or index
  }

  filterInvestors(filter: string): InvestingEntityReqRes[] {
    if (filter === null) {
      filter = '';
    }

    if (typeof filter === 'number') {
      // investing entity selected from the available options
      this.internalFilteredInvestors = this.data.investingEntitiesList.filter(x => x.id === Number(filter));
      this.setAccountHolderNameAndNickname();
      return this.internalFilteredInvestors;
    } else {
      const filterValue = filter.toLocaleLowerCase();
      if (filterValue === '') {
        this.internalFilteredInvestors = this.data.investingEntitiesList;
        return this.internalFilteredInvestors;
      }

      const filteredInvestors = this.data.investingEntitiesList.filter(investor => investor.name.toLocaleLowerCase().startsWith(filterValue));
      if (filteredInvestors.length === 1 && filteredInvestors[0].name.toLocaleLowerCase() === filterValue) {
        this.pageForm.get('investor').setValue(filteredInvestors[0].id, {emitModelToViewChange: false});
      } else {
        if (this.isAutoFilled) {
          this.pageForm.get('investor').reset();
        } else {
          this.internalFilteredInvestors = filteredInvestors;
        }
      }

      return this.internalFilteredInvestors;
    }
  }

  setAccountHolderNameAndNickname() {
    const investingEntityName = this.internalFilteredInvestors[0].name;
    this.pageForm.get('nickname').setValue(investingEntityName + ' bank account');
    this.pageForm.get('holderFullName').setValue(investingEntityName.substring(0, investingEntityName.indexOf('(') - 1));
  }
}
