<terra-dialog-header>
  <h1 class="lo_sensitive">{{ data.investingEntity?.name ? data.investingEntity.name : 'New Investing Entity' }}
  </h1>
</terra-dialog-header>
<mat-dialog-content>
  <form [formGroup]="pageForm" (ngSubmit)="submit()" id="pageForm" class="cov-form-grid">

    <div class="form-section">
      <!-- Entity Type -->
      <div class="terra-form-field-wrapper">
        <label for="ddlInvestingEntityType" class="text-input-label">Investing entity type</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="investingEntityType" id="ddlInvestingEntityType" placeholder="Select">
            <mat-option *ngFor="let investingEntityType of InvestingEntityType.listAll()" [value]="investingEntityType">
              {{ investingEntityType | enumString: InvestingEntityType }} </mat-option>
          </mat-select>
          <mat-error *ngIf="pageForm.get('investingEntityType').hasError('required')"> Required </mat-error>
        </mat-form-field>
      </div>

      <!-- Name -->
      <div class="terra-form-field-wrapper">
        <label for="txtName" class="text-input-label">Name</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtName" formControlName="name" class="lo_sensitive" />
          <mat-error *ngIf="pageForm.get('name').hasError('required')"> Required </mat-error>
          <mat-error *ngIf="pageForm.get('name').hasError('minlength')">Name must be more than one character</mat-error>
          <mat-error *ngIf="pageForm.get('name').hasError('illegalCharacter')">
            Name shouldn't contain the following characters: {{forbiddenNameCharacters.join(', ')}}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- TIN -->
      <div class="terra-form-field-wrapper tin-field new-row">
        <mat-icon class="notranslate" color="primary" matTooltipClass="terra-tooltip tin-tooltip"
          matTooltipPosition="right"
          matTooltip=
          "The TIN varies per country, for example:
           UK: The Unique Taxpayer Reference (UTR)
           US: The Employer ID Number (EIN)
           Germany: The Tax ID (IdNr)
           Israel: The Business Registration Number (ח״פ)
          ">help</mat-icon>
        <label for="txtTin" class="text-input-label">TIN (optional)</label>
        <mat-form-field appearance="outline" class=half-width>
          <input matInput id="txtTin" formControlName="tin" class="lo_sensitive uppercase">
          <mat-error *ngIf="pageForm.get('tin').hasError('required')">
            required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Accounting external ID (Yardi etc.) -->
      <div class="terra-form-field-wrapper">
        <label for="txtAccountingExternalId" class="text-input-label">Accounting ID (optional)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtAccountingExternalId" formControlName="accountingExternalId" class="lo_sensitive" />
          <mat-error *ngIf="pageForm.get('accountingExternalId').hasError('required')"> Required </mat-error>
        </mat-form-field>
      </div>

      <!-- Accreditation Date -->
      <terra-accreditation [onlyDatePicker]="true" [autoSaveDate]="false" [investingEntity]="data.investingEntity"
        [accreditationDateControl]="pageForm.get('accreditationDate')"></terra-accreditation>
    </div>

    <div class="form-section">
      <!-- Remarks -->
      <h2 class="section-title">Remarks (optional)</h2>
      <div class="terra-form-field-wrapper full-width">
        <label for="txtRemarks" class="text-input-label">(Only visible to you)</label>
        <mat-form-field appearance="outline">
          <textarea id="txtRemarks" (keydown.enter)="$event.stopPropagation()" matInput formControlName="remarks"
            class="lo_sensitive"></textarea>
        </mat-form-field>
      </div>
    </div>

    <!-- Address -->
    <div class="form-section">
      <h2 class="section-title">
        What is the address of the investing entity? (optional)
      </h2>

      <!-- Use contact location checkbox -->
      <div class="terra-form-field-wrapper">
        <mat-checkbox color="primary" formControlName="useContactLocation">
          Same address as primary contact
        </mat-checkbox>
      </div>

      <!-- Address form -->
      <terra-address-form *ngIf="pageForm.get('useContactLocation').value === false" [externalForm]="pageForm"
        [locationDetailsResponse]="initialLocationDetails" partialFormName="investingEntityLocation"
        class="inner-form-section" [formSettings]="addressFormSettings">
      </terra-address-form>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button type="submit" form="pageForm" color="primary" class="save-button">Save</button>
  <button mat-button type="button" class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
  <span *ngIf="pageForm.invalid && (isSubmitted$ | async)" class="form-error error-message-compact">
    Please review and correct the fields marked in red
  </span>
</mat-dialog-actions>