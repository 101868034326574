import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { enterAnimation } from 'src/app/shared/animations';
import { DialogWithFormBase } from 'src/app/shared/types/DialogWithFormBase';
import { InvestorContactDetailService } from './investor-contact-detail.service';
import { ContactDialogStore } from './state/contact-dialog.store';
import { ContactDialogQuery } from './state/contact-dialog.query';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { InvestorContactReqRes } from '../../models/investorContactReqRes.model';

export interface ContactDialogData {
  contactId: number;
  investingEntityId: number;
}

@Component({
  selector: 'terra-investor-contact-dialog',
  templateUrl: './investor-contact-dialog.component.html',
  styleUrls: ['./investor-contact-dialog.component.scss'],
  animations: [enterAnimation],
  providers: [ContactDialogStore, ContactDialogQuery, InvestorContactDetailService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorContactDialogComponent extends DialogWithFormBase implements OnInit {
  isEditMode = false;

  tabIndex$ = this.query.select(state => state.ui.activeTabIndex);

  contactDetails$ = this.contactDetailService.contactDetails$;

  dialogForm = this.contactDetailService.dialogForm;

  isLoadingContactData$ = this.query.selectLoading();
  isSubmitted$ = this.query.isSubmitted$;
  isGeneralServerError$ = this.contactDetailService.isGeneralServerError$;
  generalServerErrorMessage$ = this.contactDetailService.generalServerErrorMessage$;
  isSaving$ = this.query.isSaving$;
  isUnsavedChanges$ = this.query.isUnsavedChanges$;

  displayLoader$ = combineLatest([this.isLoadingContactData$, this.isSaving$]).pipe(
    map(([isLoadingData, isSaving]) => {
      return isLoadingData || isSaving;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isSaveButtonDisabled$ = combineLatest([this.isUnsavedChanges$, this.displayLoader$]).pipe(map(([isUnsavedChanges, displayLoader]) => {
    return !isUnsavedChanges || displayLoader;
  }));

  constructor(
    private viewContainerRef: ViewContainerRef,
    private query: ContactDialogQuery,
    private dialogRef: MatDialogRef<InvestorContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private context: ContactDialogData,
    private contactDetailService: InvestorContactDetailService
  ) {
    super();
    contactDetailService.viewContainerRef = viewContainerRef;
    dialogRef.addPanelClass('terra-investor-contact-dialog');

    this.isEditMode = context && context.contactId > 0;

    if (this.isEditMode) {
      this.contactDetailService.initialDialogForEditMode(context.contactId);
    } else {
      this.contactDetailService.initializeDialogForCreateMode();
    }
  }

  ngOnInit() {
    if (this.context && this.context.investingEntityId) {
      this.contactDetailService.updateTabIndex(1);
      this.contactDetailService.openInvstingEntityDialogByIdInEditMode(this.context.investingEntityId);
    }
  }

  save() {
    this.contactDetailService.save()
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        response => {
          if (response) {
            this.dialogRef.close(response);
          }
        },
        error => {
        });
  }

  getContactFullName(contact: InvestorContactReqRes) {
    return TerraUtils.getContactFullName(contact);
  }
}
