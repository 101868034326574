import AccreditationStatus from "../enums/AccreditationStatus.enum";
import { BaseResponseDto } from "./BaseResponseDto.model";

export enum AccreditationProvider
{
  None = 0,
  Manual = 1,
  VerifyInvestor = 3
}

export class AccreditationDto extends BaseResponseDto{
    public id:number;

    public accreditationRequire:boolean;

    public accreditedDate:Date;

    // public accreditedDocumentUrl:string;

    public status:AccreditationStatus

    public resourceId:string;

    public lpClientDetailsId:number;

    public investingEntityId:number;

    public investingEntityName:string;

    public providerId:string;

    public provider: AccreditationProvider;

    public downloadCertificateUrl:string;

    public organizationDetailsId:number;

    public organizationName:string;
    
    public gpClientDetailsId:number;
    
    public holdingId:number;
    
}