import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {CountryModel} from 'src/app/shared/models/CountryModel';
import {InvestorContactDetailService} from '../investor-contact-detail.service';
import {ContactDialogQuery} from '../state/contact-dialog.query';
import {
  InvestorContactReferrerDialogComponent,
  ContactReferrerEditDialogContext
} from '../investor-contact-referrer-dialog/investor-contact-referrer-dialog.component';
import {AddressFields, AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';
import {AdditionalEmailsDialogComponent} from './additional-emails-dialog/additional-emails-dialog.component';
import {BaseResponseDto} from '../../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../../services/utils.service';

@Component({
  selector: 'terra-investor-contact-details-tab',
  templateUrl: './investor-contact-details-tab.component.html',
  styleUrls: ['./investor-contact-details-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorContactDetailsTabComponent extends OnDestroyMixin implements OnInit {

  // const;
  readonly today = new Date();
  readonly forbiddenNameCharacters = this.contactDetailService.forbiddenNameCharacters;
  readonly separatorKeysCodes = [ENTER, COMMA];
  readonly defaultCommonTags = ['residential', 'quick mover', 'commercial', 'east coast', 'up to $1M'];
  tinTooltip = `In the US: For individuals, the TIN can be a Social Security Number (SSN) or an Individual Taxpayer Identification Number (ITIN). For organizations, the TIN is the EIN.`;

  pageForm = this.contactDetailService.contactDetailsForm;

  contactDetails$ = this.query.contact$;

  isLoadingDataContact$ = this.query.selectLoading();

  allContactReferrers$ = this.query.allContactReferrers$;

  isSaving$ = this.query.isSaving$;

  isSubmitted$ = this.query.isSubmitted$;

  displayAddress$ = new BehaviorSubject(false);

  addressFormSettings = new AddressFormSettings(undefined, AddressFields.Country, false, false);

  allTags$ = this.contactDetailService.allTags$.pipe(map(tags => tags && tags.length > 0 ? tags : []));

  mostUsedTags$ = this.contactDetailService.mostUsedTags$.pipe(
    map(mostUsedTags => mostUsedTags && mostUsedTags.length > 0 ? mostUsedTags : this.defaultCommonTags));

  // tags that are yet selected - to display in the autocomplete
  filteredTagsForAutocomplete$: Observable<any[]>;

  get contactTagsTermInput() {
    return this.pageForm.get('contactTagsTerm') as UntypedFormControl;
  }

  selectedContactTags$ = this.contactDetailService.contactDetails$.pipe(map(contact => contact.contactTags));

  filteredMostUsedTags$ = combineLatest([this.mostUsedTags$, this.selectedContactTags$.pipe(startWith([]))]).pipe(
    map(([mostUsedTags, selectedTags]) => {
      return mostUsedTags.filter(tag => !selectedTags.find(t => t === tag));
    }),
    shareReplay(1)
  );

  @ViewChild('tagsAutocomplete') matAutocomplete: MatAutocomplete;

  additionalEmails$: Observable<string> = this.pageForm.get('additionalEmails').valueChanges.pipe(startWith([]), shareReplay(1));

  constructor(
    private query: ContactDialogQuery,
    private contactDetailService: InvestorContactDetailService,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeTagsAutocomplete();
    this.displayAddress$.next(true);
  }

  addTagAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    this.contactDetailService.addContactTag(value);

    // AFter typing a new tag and clicking enter, it's being added, and the text fields is cleared
    if (input) {
      input.value = '';
    }
    this.clearContactTagsTermInput();
  }

  selectedTagFromAutocomplete(event: MatAutocompleteSelectedEvent): void {
    this.contactDetailService.addContactTag(event.option.viewValue);
    this.clearContactTagsTermInput();
  }

  removeTag(tag: string): void {
    this.contactDetailService.removeContactTag(tag);
  }

  selectStaticChip(tag: string) {
    this.contactDetailService.addContactTag(tag);
  }

  conutryTrackByFn(index, item: CountryModel) {
    return item.id; // or index
  }

  addContactReferrerDialog() {
    const dialogConfig = new MatDialogConfig<ContactReferrerEditDialogContext>();
    const data = new ContactReferrerEditDialogContext();
    data.includeFindFeatureDirection = true;
    dialogConfig.data = data;

    this.dialog.open(InvestorContactReferrerDialogComponent, dialogConfig)
      .afterClosed().pipe(
      untilComponentDestroyed(this),
      filter(contactReferrer => contactReferrer),
      switchMap(contactReferrer => this.contactDetailService.addContactReferrer(contactReferrer)),
    ).subscribe(
      () => {},
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
      }
    );
  }

  additionalEmailsEdit() {
    const additionalEmailsControl = this.pageForm.get('additionalEmails');
    const config = new MatDialogConfig();
    config.data = additionalEmailsControl.value;
    this.dialog.open(AdditionalEmailsDialogComponent, config).afterClosed()
      .pipe(filter(result => result !== undefined))
      .subscribe(emails => {
        additionalEmailsControl.setValue(emails);
        additionalEmailsControl.markAsDirty();
        additionalEmailsControl.updateValueAndValidity();
      });
  }

  private initializeTagsAutocomplete() {
    // Filter autocomplete results while typing
    this.filteredTagsForAutocomplete$ = this.contactTagsTermInput.valueChanges.pipe(
      startWith(''),
      switchMap(term => combineLatest([of(term), this.allTags$, this.selectedContactTags$])),
      map(([filterTerm, allTags, selectedTags]) => {
        // only not selected results
        const unselectedTags = allTags.filter(t => selectedTags.indexOf(t) === -1);
        // From the unselected tags, filter using the search term
        if (!filterTerm) {
          return unselectedTags;
        } else {
          return unselectedTags.filter(tag => tag.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1);
        }
      }));
  }

  private clearContactTagsTermInput() {
    this.contactTagsTermInput.setValue('');
  }
}
