import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractRestService } from 'src/app/shared/types/AbstractRestService';
import { HttpService } from '../http.service';
import { ContactReferrerReqRes } from 'src/app/shared/models/ContactReferrerReqRes.model';
import { SearchOptionsRequest } from 'src/app/shared/models/SearchOptionsRequest.model';

@Injectable({
  providedIn: 'root'
})
export class ContactReferrerDataService extends AbstractRestService<ContactReferrerReqRes, ContactReferrerReqRes> {

  protected readonly baseEndpoint$: Observable<string> = of('contacts/referrers/');

  constructor(http: HttpService) {
    super(http);
  }

  getAll() {
    const searchOptions = new SearchOptionsRequest();
    searchOptions.pageSize = 1000;
    return this.getList(searchOptions).pipe(map(response => response.rows));
  }
}
