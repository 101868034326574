
export enum AccreditationStatus {
    None = 0,
    NotAccredited = 1,
    Accredited = 2,
    Requested = 3,
    Pending = 4,
    Denied = 5,
    Expired = 6,
    Canceled = 7,
    InvestorViewed = 8
}

export namespace AccreditationStatus {
    export function toString(accreditationStatus: AccreditationStatus): string {
        switch (accreditationStatus) {
            case AccreditationStatus.NotAccredited:
                return 'Not Accredited';
            case AccreditationStatus.Accredited:
                return 'Accredited';
            case AccreditationStatus.Requested:
            case AccreditationStatus.InvestorViewed:
                return 'Accreditation Requested';
            case AccreditationStatus.Pending:
                    return 'Pending by Investor';
            case AccreditationStatus.Denied:
                return 'Accreditation Denied';
            case AccreditationStatus.Expired:
                return 'Accreditation Expired';
            case AccreditationStatus.Canceled:
                return 'Accreditation Canceled';
            default:
                return 'Not Accredited';
        }
    }

    export function listAll(): AccreditationStatus[] {
        return [
            AccreditationStatus.NotAccredited,
            AccreditationStatus.Accredited,
            AccreditationStatus.Requested,
            AccreditationStatus.Pending,
            AccreditationStatus.Denied,
            AccreditationStatus.Expired,
            AccreditationStatus.Canceled,
            AccreditationStatus.InvestorViewed
        ];
    }

    export function parse(str: string): AccreditationStatus {
        return AccreditationStatus[str];
    }

    export function getClassStatus(status: AccreditationStatus) {
        if(!!status){
            switch (status) {
                case AccreditationStatus.NotAccredited:
                    return 'neutral';
                case AccreditationStatus.Accredited:
                    return 'success';
                case AccreditationStatus.Requested:
                case AccreditationStatus.InvestorViewed:
                    return 'request';
                case AccreditationStatus.Pending:
                    return 'pending';
                case AccreditationStatus.Expired:
                case AccreditationStatus.Denied:
                case AccreditationStatus.Canceled:
                    return 'error';
                default:
                    return 'neutral';
            }
        }
        return 'neutral';
    }

    export function getIconStatus(status: AccreditationStatus) {
        if (status) {
          switch (status) {
            case AccreditationStatus.NotAccredited:
              return 'do_not_disturb_on';
            case AccreditationStatus.Accredited:
              return 'check_circled';
            case AccreditationStatus.Requested:
            case AccreditationStatus.InvestorViewed:
                return 'schedule';
            case AccreditationStatus.Pending:
              return 'schedule';
            case AccreditationStatus.Expired:
              return 'hourglass_bottom';
            case AccreditationStatus.Denied:
              return 'warning';
          }
        }
        return 'do_not_disturb_on';
      }
}

export default AccreditationStatus;
