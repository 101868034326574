<terra-dialog-header>
  <h1 class="lo_sensitive">
    {{ context && context.contactReferrer && context.contactReferrer.id ? 'Edit Contact Referrer' : 'New Contact Referrer' }}
  </h1>
</terra-dialog-header>

<mat-dialog-content>
  <form *ngIf="pageForm" [formGroup]="pageForm" (ngSubmit)="submit()" id="pageForm" class="cov-form-grid">

    <div class="form-section">

      <!-- Name -->
      <div class="terra-form-field-wrapper full-width">
        <label for="txtName" class="text-input-label">Name</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtName" tabindex="-1" formControlName="name" class="lo_sensitive" />
          <mat-error *ngIf="pageForm.get('name').hasError('required')"> Required </mat-error>
          <mat-error *ngIf="pageForm.get('name').hasError('minlength')">Name must be more than one character</mat-error>
          <mat-error *ngIf="pageForm.get('name').hasError('maxlength')">Name must be a maximum of
            {{ pageForm.get('name').errors.maxlength | terraNumber }} characters</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <p *ngIf="context?.includeFindFeatureDirection" class="find-feature-directions">
    Manage existing contact referrers in the Manage Contact Referrers screen, accessible under the Actions menu in the Contacts page.
  </p>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button type="submit" form="pageForm" color="primary" class="save-button">Save</button>
  <button mat-button type="button" class="cancel-button tall-button" [mat-dialog-close]="undefined">Cancel</button>
</mat-dialog-actions>
