

export enum GeneralSettingKeys{
    AccreditationPrice  = "AccreditationPrice"


}

export namespace GeneralSettingKeys{
    export function getAll(): string[]{
        return [
            GeneralSettingKeys.AccreditationPrice
        ]
    }
}
