import {Component, OnInit, ViewContainerRef, ChangeDetectionStrategy, Input} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {switchMap, take, filter, tap} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {InvestorContactDetailService} from '../investor-contact-detail.service';
import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {DialogService} from 'src/app/services/dialog.service';
import {ConfirmDialogParams} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {AlertDialogParams} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {ContactDialogQuery} from '../state/contact-dialog.query';
import {InvestingEntityViewDialogComponent, InvestingEntityViewDialogContext} from '../../investing-entity-view-dialog/investing-entity-view-dialog.component';
import {EditBankAccountContext} from '../../../../bankAccounts/components/edit-bank-account/EditBankAccountContext.model';
import {EditBankAccountComponent} from '../../../../bankAccounts/components/edit-bank-account/edit-bank-account.component';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {PermissionService} from 'src/app/permission/permission.service';

@Component({
  selector: 'terra-investor-contact-investing-entities-tab',
  templateUrl: './investor-contact-investing-entities-tab.component.html',
  styleUrls: ['./investor-contact-investing-entities-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorContactInvestingEntitiesTabComponent extends OnDestroyMixin implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  @Input() isAgentContact = false;

  // consts
  InvestingEntityType = InvestingEntityType;

  investingEntities$ = this.query.investingEntities$;

  displayedColumns: string[] = ['name', 'investingEntityType', 'remarks', 'accountingExternalId', 'action'];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private permissionService: PermissionService,
    private query: ContactDialogQuery,
    private contactDetailService: InvestorContactDetailService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  ngOnInit() {
  }

  openCreateInvestingEntityDialog() {
    this.contactDetailService.openCreateInvestingEntityDialog();
  }

  openEditInvestingEntityDialog(investingEntityToEdit: InvestingEntityReqRes = null) {
    this.contactDetailService.openInvestingEntityDialogInEditMode(investingEntityToEdit);
  }

  viewInvestingEntity(investingEntityId: number) {
    if (!investingEntityId) {
      return;
    }
    const config = new MatDialogConfig<InvestingEntityViewDialogContext>();
    config.data = {
      investingEntityId
    };
    this.dialog.open(InvestingEntityViewDialogComponent, config);
  }


  delete(investingEntity: InvestingEntityReqRes) {
    this.investingEntities$.pipe(
      untilComponentDestroyed(this),
      take(1),
      tap(investingEntities => {
        if (investingEntities.length === 1) {
          this.dialogService.alertDialog(
            {title: 'Unable to delete investment entity', description: 'A contact must have at least one investing entity.'} as AlertDialogParams);
        }
      }),
      filter(investingEntities => investingEntities.length > 1),
      switchMap(x => {
        return this.dialogService.confirmDialog(
          {
            title: `Delete investing entity`,
            description: `Are you sure you want to delete ${investingEntity.name}?`,
            actionLabel: 'Delete'
          } as ConfirmDialogParams).afterClosed();
      })
    )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.contactDetailService.deleteInvestingEntity(investingEntity);
        }
      });
  }

  addBankAccount(investingEntity: InvestingEntityReqRes) {
    const dialogConfig = new MatDialogConfig<EditBankAccountContext>();
    dialogConfig.data = {investingEntity} as EditBankAccountContext;
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'create-bank-account-dialog';
    dialogConfig.width = '52%';

    this.dialog.open(EditBankAccountComponent, dialogConfig)
      .afterClosed().subscribe(res => {
      if (res) {
        const message = `${res.holderFullName}'s bank account was successfully added`;
        this.snackbarService.showGeneralMessage(message, 5);
      }
    });
  }
}
