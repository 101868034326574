import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { CustomValidators } from "src/app/shared/validators/custom.validators";

@Component({
  selector: "terra-additional-emails-dialog",
  templateUrl: "./additional-emails-dialog.component.html",
  styleUrls: ["./additional-emails-dialog.component.scss"],
})
export class AdditionalEmailsDialogComponent implements OnInit {
  readonly MAX_EMAILS_NUMBER = 25;
  pageForm: UntypedFormGroup;

  get emailsFormArray(): UntypedFormArray {
    return this.pageForm.get("emails") as UntypedFormArray;
  }

  constructor(
    private dialogRef: MatDialogRef<AdditionalEmailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private emails: string[],
    private fb: UntypedFormBuilder
  ) {
    dialogRef.addPanelClass('terra-additional-emails-dialog');
  }

  ngOnInit() {
    this.generateForm();
    this.populateForm(this.emails);
  }

  addEmail(email: string = null) {
    this.emailsFormArray.push(
      new UntypedFormControl(email, CustomValidators.EmailWithSpaces)
    );
  }

  removeEmail(indexToRemove: number) {
    this.emailsFormArray.removeAt(indexToRemove);
  }

  clearEmail(indexToRemove: number) {
    this.emailsFormArray.get(indexToRemove.toString()).reset();
  }

  ok() {
    if(!this.pageForm.valid) {
      return;
    }
    const emails = this.emailsFormArray.value.filter(email => !!email);
    this.dialogRef.close(emails);
  }

  private generateForm() {
    this.pageForm = this.fb.group({
      emails: this.fb.array([]),
    });
  }

  private populateForm(emails: string[]) {
    if (!emails || emails.length === 0) {
      this.addEmail();
    } else {
      emails.forEach((email) => {
        this.addEmail(email);
      });
    }
  }
}
