<terra-dialog-header>
  <h1>{{ bankAccountId ? 'Edit Bank Account' : 'New Bank Account' }}</h1>
</terra-dialog-header>

<div class="content-wrapper" [ngClass]="{ 'loading': showInternalLoader$ | async  }">
  <terra-loader class="page-loader" *ngIf="showInternalLoader$ | async"></terra-loader>
  <mat-dialog-content class="main-content">
    <div class="info-message read-only-message" *ngIf="readOnlyMode">
      This account is associated with an asset. Only the account nickname can be edited.
    </div>

    <form class="form-grid-new terra-error-style" id="dialogFormEditBank" (ngSubmit)="save()" [formGroup]="pageForm"
          (keydown.enter)="$event.preventDefault()" cdkScrollable>
      <div class="form-grid terra-error-style">

        <!-- Investor -->
        <div class="terra-form-field-wrapper" *ngIf="data?.investingEntitiesList">
          <label for="investorSelect" class="text-input-label">Investing entity</label>
          <mat-form-field appearance="outline">
            <input autocomplete="nope" type="text" id="investorSelect" #investingEntityInput terraForceAutoCompleteSelection placeholder="Select"
                   matInput formControlName="investor" [matAutocomplete]="autoComplete" (focus)="$event.target.select()"
                   (cdkAutofill)="setAutofillState($event.isAutofilled)">
            <mat-icon [ngClass]="{'notranslate': true, 'drop-down-arrow': true, 'error-color': isError(), 'default-color': !isError()}" matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
              <mat-option *ngFor="let investor of filteredInvestors$ | async; trackBy: investorTrackByFn"
                          [value]="investor.id">
                <span class="notranslate">
					<ng-container *ngIf="allowInvestorName$ | async">
						{{ investor.name }}
					</ng-container>
					<ng-container *ngIf="!(allowInvestorName$ | async)">
						{{ investor.nickname }}
					</ng-container>
				</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="pageForm.get('investor').hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Account nickname -->
        <div class="terra-form-field-wrapper bypass-read-only">
          <label for="txtNickname" class="text-input-label">Account nickname</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtNickname" formControlName="nickname" autocomplete="account-name" class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('nickname').hasError('required')"> Required</mat-error>
          </mat-form-field>
        </div>

        <!-- Holder Name -->
        <div class="terra-form-field-wrapper">
          <label for="txtHolderFullName" class="text-input-label">Account holder's name</label>
          <mat-form-field appearance="outline">
            <input matInput id="txtHolderFullName" formControlName="holderFullName" autocomplete="account-holder-name"
                   class="lo_sensitive"/>
            <mat-error *ngIf="pageForm.get('holderFullName').hasError('required')"> Required</mat-error>
            <mat-error *ngIf="pageForm.get('holderFullName').hasError('minlength')"> Please enter account holder name</mat-error>
          </mat-form-field>
        </div>

        <ng-container>
          <h2 class="section-title">
            Bank account details
          </h2>

          <terra-client-bank-account #bankAccountComponent [isReadOnlyMode]="readOnlyMode" [externalForm]="pageForm"
                                     partialFormName="bankDetails" [enablePreferredCurrency]="enablePreferredCurrency" [bankAccountModel]="bankAccountDetails"
                                     [isForceBankNameAndBranchCode]="isCollectWitholdingTaxData()" class="full-width">

            <!-- Injected information (will be placed in a specific place inside the bank account component) -->
            <div class="injected-currency-information">
              <!-- Short explanation to be injected to the bank account component -->
              <div class="recommended-currency-short"
                   *ngIf="{isRecommendedCurrencySelected: bankAccountComponent.isRecommendedCurrencySelected | async, preferredCurrency: bankAccountComponent.preferredCurrency | async } as vm">

                <p *ngIf="vm.isRecommendedCurrencySelected" class="recommended-currency-short-text success-message">
                  <mat-icon class="notranslate">check</mat-icon>
                  Great! Your investor will receive the funds in the local currency of this country, which means Covercy will be able to send
                  the funds faster and guarantee the exact amount received in {{ bankAccountComponent.selectedCountry?.defaultCurrency.iso }}.
                  <a class="light link"
                     (click)="currencyLearnMore(true, bankAccountComponent.selectedCountry?.defaultCurrency, vm.preferredCurrency)"> Learn
                    more</a>
                </p>

                <p *ngIf="!vm.isRecommendedCurrencySelected" class="recommended-currency-short-text warn-message">
                  <mat-icon class="notranslate">notification_important</mat-icon>
                  The currency you selected above is not the local currency in {{ bankAccountComponent.selectedCountry?.name }}.
                  It is highly advised, both in speed of transfer and in fees,
                  that you choose <strong>{{ bankAccountComponent.selectedCountry?.defaultCurrency.symbol }}
                  {{ bankAccountComponent.selectedCountry?.defaultCurrency.iso }} </strong> instead.
                  <a class="light link"
                     (click)="currencyLearnMore(false, bankAccountComponent.selectedCountry?.defaultCurrency, vm.preferredCurrency)">
                    Learn why
                  </a>
                </p>
              </div>
              <!-- <terra-distribution-rates-compare-table></terra-distribution-rates-compare-table> -->
            </div>
          </terra-client-bank-account>
        </ng-container>

        <ng-container>
          <h2 class="section-title">
            What is the address of the bank account holder?
          </h2>

          <!-- Address form -->
          <terra-address-form
            [externalForm]="pageForm" [locationDetailsResponse]="bankAccountDetails.holderLocationDetails"
            partialFormName="holderLocationDetails" class="full-width form-grid-new" [formSettings]="addressFormSettings">
          </terra-address-form>
        </ng-container>

        <ng-container>
          <h2 class="section-title">
            Tax related information
          </h2>

          <!-- Is An Organization Account -->
          <div class="terra-form-field-wrapper">
            <mat-checkbox color="primary" formControlName="isAnOrganizationAccount" class="regular-checkbox is-organization-checkbox">
              <h5>Account holder is a business/partnership</h5>
            </mat-checkbox>
          </div>

          <!-- Entity Type -->
          <div [ngClass]="{'terra-form-field-wrapper': true, 'new-row': true, 'bypass-read-only': isIgnoreReadOnlyBeneficiaryEntityType}"
               *ngIf="isCollectWitholdingTaxData()">
            <label for="ddlBeneficiaryEntityType" class="text-input-label">Recipient entity type</label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="beneficiaryEntityType" id="ddlBeneficiaryEntityType" placeholder="Select">
                <mat-option *ngFor="let beneficiaryEntityType of BeneficiaryEntityType.listAll()" [value]="beneficiaryEntityType">
                  {{ beneficiaryEntityType | enumString: BeneficiaryEntityType }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="pageForm.get('beneficiaryEntityType').hasError('required')"> Required</mat-error>
            </mat-form-field>
          </div>

          <!-- TIN -->
          <div [ngClass]="{'terra-form-field-wrapper': true, 'tin-field': true, 'bypass-read-only': isIgnoreReadOnlyBeneficiaryTin}"
               *ngIf="isCollectWitholdingTaxData() && getAddressCountry()">
            <mat-icon class="notranslate" color="primary" matTooltipClass="terra-tooltip tin-tooltip" matTooltipPosition="right" matTooltip="The TIN varies per country, for example:
                      UK: The Unique Taxpayer Reference (UTR)
                      US: The Employer ID Number (EIN)
                      Germany: The Tax ID (IdNr)
                      Israel: The Business Registration Number (ח״פ)">help
            </mat-icon>
            <label for="txtBeneficiaryTin" class="text-input-label">Recipient tax identification number (TIN) in
              {{ getAddressCountry()?.name }}</label>
            <mat-form-field appearance="outline" class=half-width>
              <input matInput id="txtBeneficiaryTin" formControlName="beneficiaryTin" class="lo_sensitive uppercase">
              <mat-error *ngIf="pageForm.get('beneficiaryTin').hasError('required')">
                required
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>

      </div>
    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button type="submit" form="dialogFormEditBank" mat-flat-button color="primary" class="page-cta" [disabled]="showInternalLoader$ | async">
    Save
  </button>
  <button [disabled]="showInternalLoader$ | async" [mat-dialog-close]="undefined" type="button" mat-button color="primary"
          class="cancel-button tall-button">
    Cancel
  </button>
  <!-- Error message -->
  <p *ngIf="pageForm.invalid && isSubmitted" class="error-message">
    Please review and correct the fields marked in red
  </p>
  <p *ngIf="pageForm.valid && (isGeneralServerError$ | async)" #serverError class="error-message">
    <span [innerHTML]="generalServerErrorMessage"></span>
  </p>
</mat-dialog-actions>
