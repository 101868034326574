import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';

import {HttpService} from 'src/app/services/http.service';
import {AbstractRestService} from 'src/app/shared/types/AbstractRestService';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {
  InvestingEntitySearchOptionsRequest
} from 'src/app/dashboard/contacts/components/investing-entities-display-table/InvstingEntitySearchOptionsRequest.model';
import {ReportStatus} from 'src/app/shared/enums/InvestingEntityReportStatus.enum';
import HoldingStatus from 'src/app/shared/enums/HoldingStatus.enum';
import {LoggerService} from 'src/app/shared/errors/logger.service';
import {SearchOptionsResponse} from '../../shared/models/SearchOptionsResponse.model';

export interface InvestingEntityDetailsItem {
  holdingId: number;
  assetName: string;
  date: Date;
  status: HoldingStatus;
  amountInvested: number;
  amountPerCurrency: string;
  currencySymbol: string;
  currencyIso: string;
}

@Injectable({
  providedIn: 'root'
})
export class GpInvestingEntityDataService extends AbstractRestService<InvestingEntityReqRes, InvestingEntityReqRes> {

  protected readonly baseEndpoint$ = of('investing-entity/');

  constructor(http: HttpService, private logger: LoggerService) {
    super(http);
  }

  protected getSearchOptionsToQuerystring(options: SearchOptionsRequest): string {
    const investingEntityOptions = options as InvestingEntitySearchOptionsRequest;
    let extendedQueryString = '';
    if (investingEntityOptions.tags && investingEntityOptions.tags.length > 0) {
      extendedQueryString = investingEntityOptions.tags.map(tag => `&tags=${tag}`).join('');
    }
    if (investingEntityOptions.fundraisingId > 0) {
      extendedQueryString += `&fundraisingId=${investingEntityOptions.fundraisingId}`;
    }
    if (investingEntityOptions.contactId) {
      extendedQueryString += `&contactId=${investingEntityOptions.contactId}`;
    }

    if (investingEntityOptions.contactReferrerId) {
      extendedQueryString += `&contactReferrerId=${investingEntityOptions.contactReferrerId}`;
    }

    if (investingEntityOptions.withContactName === true) {
      extendedQueryString += `&withContactName=true`;
    }

    if (investingEntityOptions.excludeAgentContacts) {
      extendedQueryString += `&excludeAgentContacts=${investingEntityOptions.excludeAgentContacts}`;
    }

    if (investingEntityOptions.holdingId) {
      extendedQueryString += `&holdingId=${investingEntityOptions.holdingId}`;
    }
    if (investingEntityOptions.distributionId) {
      extendedQueryString += `&distributionId=${investingEntityOptions.distributionId}`;
    }


    return super.getSearchOptionsToQuerystring(options) + extendedQueryString;
  }


  getContributionsReport(investingEntityId: number, reportStatus: ReportStatus) {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<InvestingEntityDetailsItem[]>(
        `${baseEndpoint}${investingEntityId}/contributions-report?status=${reportStatus}`)),
      catchError(error => {
        this.logger.error('GpInvestingEntityDataService => getContributionsReport()', error);
        return throwError(error);
      }),
    );
  }

  getDistributionsReport(investingEntityId: number, reportStatus: ReportStatus) {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<InvestingEntityDetailsItem[]>(
        `${baseEndpoint}${investingEntityId}/distributions-report`)),
      catchError(error => {
        this.logger.error('GpInvestingEntityDataService => getDistributionsReport()', error);
        return throwError(error);
      }),
    );
  }

  public getAll(): Observable<InvestingEntityReqRes[]> {
    return this.baseEndpoint$.pipe(
      switchMap(endpoint => this.http.getTyped<InvestingEntityReqRes[]>(`${endpoint}/all`)),
      catchError(err => {
        this.logger.error('GpInvestingEntityDataService => getAll()', err);
        return throwError(err);
      })
    );
  }
}
